import MyLink from '@/components/zthd/my-link'
import { toast } from 'sonner'

const CampaignNameCell = ({ row }) => {
  const campaign = row.original

  const handleCopyId = () => {
    navigator.clipboard.writeText(campaign.id)
    toast(`Campaign ID ${campaign.id} copied to clipboard`)
  }

  return (
    <div className="flex flex-col">
      {/* {JSON.stringify(campaign)} */}
      <div className="font-medium">
        <MyLink target="_blank" to={`/troas/campaigns/${campaign.id}`}>
          {campaign.name}
        </MyLink>
      </div>
      <div
        className="text-xs text-muted-foreground cursor-pointer"
        onClick={handleCopyId}
      >
        ID: {campaign.id}
      </div>
    </div>
  )
}

export default CampaignNameCell
