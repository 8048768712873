import * as React from 'react'
import { cva } from 'class-variance-authority'
import { cn } from '@/lib/utils'

const badgeVariants = cva(
  'inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-md text-sm font-bold transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0',
  {
    variants: {
      variant: {
        default: 'bg-gray-100 text-gray-600 ring-gray-600/10 hover:bg-gray-100',
        primary: 'bg-blue-100 text-blue-600 ring-blue-600/10 hover:bg-blue-100',
        success:
          'bg-green-100 text-green-600 ring-green-600/10 hover:bg-green-100',
        warning:
          'bg-yellow-100 text-yellow-600 ring-yellow-600/10 hover:bg-yellow-100',
        danger: 'bg-red-100 text-red-600 ring-red-600/10 hover:bg-red-100',
      },
      size: {
        default: 'h-8 px-4 py-2',
        sm: 'h-6 rounded-md px-3 text-xs',
        lg: 'h-10 rounded-md px-8',
        icon: 'h-8 w-8',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
)

const CustomBadge = React.forwardRef(
  ({ className, variant, size, ...props }, ref) => {
    return (
      <span
        ref={ref}
        className={cn(badgeVariants({ variant, size }), className)}
        {...props}
      />
    )
  },
)

CustomBadge.displayName = 'CustomBadge'

export { CustomBadge, badgeVariants }
