import { Outlet } from 'react-router-dom'
import { useRole } from '@/hooks/auth'
import { Navigate } from 'react-router-dom'
import { useAuthStore } from '@/hooks'
import { Role } from '@/constants/role'
import Tabs from '@/components/Tabs'

const tabs = [
  { name: 'Roas', to: '/troas/campaigns' },
  { name: 'Notes', to: '/troas/notes' },
  { name: 'Revenue Structure', to: '/troas/rs' },
  { name: 'Summary', to: '/troas/summary' },
]

const Layout = () => {
  const isBuyer = useRole(Role.Buyer)
  const isXN = useAuthStore((state) => state.auth?.user?.id === '2426ca7f')
  if (isBuyer || isXN) {
    return (
      <div className="flex flex-col gap-4">
        <Tabs datas={tabs} />
        <Outlet />
      </div>
    )
  }
  return <Navigate to="/403" />
}

export default Layout
