import { create } from 'zustand'

// Store for managing goal editor state
export const useGoalEditorStore = create((set) => ({
  editing: false,
  monthId: '', // Now stores a composite ID: "YYYYMM_country_pid"
  currentGoal: 0,
  toggleEditing: () => set((state) => ({ editing: !state.editing })),
  startEditing: (compositeId, currentGoal) => set({ 
    editing: true, 
    monthId: compositeId, 
    currentGoal 
  }),
  stopEditing: () => set({ 
    editing: false,
    // We need to reset these values to ensure a clean state for next edit
    monthId: '',
    currentGoal: 0
  }),
}))

// Month dropdown store
export const useMonthDropdownStore = create((set, get) => ({
  value: '',
  setValue: (value) => {
    // Only update if value changed
    if (get().value !== value) {
      set({ value })
    }
  },
})) 