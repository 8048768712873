import {
  Drawer,
  DrawerContent,
  DrawerTitle,
  DrawerDescription,
} from '@/components/ui/drawer'
import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { useMemo } from 'react'
import { create } from 'zustand'

import { usePid2 } from '@/components/DropdownPid'

import dayjs from 'dayjs'

import useSWR from 'swr'
import { isSelectedPid } from '@/components/DropdownPid'

import DataTableWithBorder from '@/components/zthd/data-table/data-table-with-border'
import localizedFormat from 'dayjs/plugin/localizedFormat'
dayjs.extend(localizedFormat)
import { useCountry, isSelectedCountry } from '@/components/DropdownCountry'

import {
  IndexColumn,
  CampaignNameColumn,
  ReasonColumn,
  CreatedAtColumn,
} from '@/components/roas/notes/columns'

const newNoteColumns = [
  IndexColumn,
  CampaignNameColumn,
  ReasonColumn,
  CreatedAtColumn,
]

export const useNoteStore = create((set) => ({
  date: '',
  open: false,
  showNotes: (payload) => {
    const { date } = payload
    set({
      date: date,
      open: true,
    })
  },
  onOpenChange: (value) => {
    if (value) {
      set({ open: true })
    } else {
      set({ open: false, date: '' })
    }
  },
}))

export const useOldNotes = (date) => {
  const pids = usePid2((state) => state.pids)
  const country = useCountry((state) => state.value)
  const { data } = useSWR(
    date < 20250208 ? ['/notes', { startDate: date, endDate: date }] : null,
  )

  return useMemo(() => {
    if (!data) return []
    return data.filter(
      (note) =>
        isSelectedPid(note.pid, pids) &&
        isSelectedCountry(note.country, country),
    )
  }, [data, pids, country])
}

export const useNewNotes = (date) => {
  const pids = usePid2((state) => state.pids)
  const country = useCountry((state) => state.value)

  const { data } = useSWR(date >= 20250208 ? ['/troas/notes', { date }] : null)

  const notes = useMemo(() => {
    if (!data?.data.notes) return []

    return data.data.notes?.filter(
      (note) =>
        isSelectedPid(note.pid, pids) &&
        isSelectedCountry(note.country, country),
    )
  }, [data, pids, country])
  return notes
}

const NotesDrawer = () => {
  const { open, onOpenChange, date } = useNoteStore()

  if (!open) return null

  return (
    <Drawer open={open} onOpenChange={onOpenChange}>
      <DrawerTitle>Notes</DrawerTitle>
      <DrawerDescription></DrawerDescription>
      <DrawerContent>
        <div className="mx-auto w-full max-w-auto p-4 overflow-y-auto max-h-80">
          {date}
          {date <= 20250210 ? (
            <OldNotes date={date} />
          ) : (
            <NewNotes date={date} />
          )}
        </div>
      </DrawerContent>
    </Drawer>
  )
}

const NewNotes = ({ date }) => {
  const notes = useNewNotes(date)
  const table = useReactTable({
    data: notes,
    columns: newNoteColumns,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <>
      {/* {JSON.stringify(data.data.notes)} */}
      {/* {country} */}
      <DataTableWithBorder table={table} />
    </>
  )
}

const OldNotes = ({ date }) => {
  const notes = useOldNotes(date)

  const columns = [
    // { id: 'index', header: 'Index', cell: ({ row }) => row.index + 1 },
    {
      accessorKey: 'pid',
      header: 'Pid',
      cell: ({ row }) => row.getValue('pid'),
    },
    {
      accessorKey: 'country',
      header: 'Country',
      cell: ({ row }) => row.getValue('country'),
    },
    {
      accessorKey: 'packageName',
      header: 'Package name',
      cell: ({ row }) => row.getValue('packageName'),
    },
    {
      accessorKey: 'cost',
      header: 'Note',
      cell: ({ row }) => row.getValue('cost'),
    },
    {
      accessorKey: 'updateTime',
      header: 'Logtime',
      cell: ({ row }) => {
        const updateTime = row.getValue('updateTime')
        const formatted = dayjs(updateTime).format('YYYY-MM-DD LT')
        return formatted
      },
    },
  ]

  const table = useReactTable({
    data: notes,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })
  return <DataTableWithBorder table={table} />
}

export default NotesDrawer
