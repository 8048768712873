import { useNoteStore } from '@/features/roi/NotesDrawer'
import Td2 from '@/components/zthd/Td2'
import { BookmarkFilledIcon, BookmarkIcon } from '@radix-ui/react-icons'
import MyLink from '@/components/zthd/my-link'
import { getWeek } from '@/lib/date-utils'
import { useOldNotes, useNewNotes } from '@/features/roi/NotesDrawer'

const DateCell = ({ row, pid }) => {
  const original = row.original
  const date = original.date

  const [, week] = getWeek(date)
  const showNotes = useNoteStore((state) => state.showNotes)

  const oldNotes = useOldNotes(date)
  const newNotes = useNewNotes(date)

  const hasNotes = oldNotes?.length > 0 || newNotes?.length > 0

  return (
    <Td2
      line2={
        <div className="flex gap-1 justify-end">
          {hasNotes && (
            <BookmarkFilledIcon
              className="text-primary"
              onClick={() => showNotes({ date })}
            />
          )}
          (周{week})
        </div>
      }
    >
      <MyLink to={`cc/${pid}/${date}`}>{date}</MyLink>
    </Td2>
  )
}

export default DateCell
