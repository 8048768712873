import {
  CartesianGrid,
  Area,
  AreaChart,
  XAxis,
  YAxis,
  ReferenceLine,
} from 'recharts'
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardFooter,
} from '@/components/ui/card'
import { ChartContainer, ChartTooltip } from '@/components/ui/chart'
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { ScrollArea } from '@/components/ui/scroll-area'
import { format } from 'date-fns'
import { useRoasStore } from '@/stores/roas'
import { useState } from 'react'
import { colorRules } from '../../lib/color-rules'

const Day0Chart = ({ roas }) => {
  const { displayMode } = useRoasStore()
  const [selectedDay, setSelectedDay] = useState(0)

  if (!roas?.dailyData) return null

  const showPercentage = displayMode === 'percentage'
  const maxDay = roas.summary?.maxDay ?? 0

  // Transform data for chart
  const chartData = roas.dailyData
    .map((row) => {
      // Calculate cumulative value up to selected day
      let cumulativeValue = 0
      for (let i = 0; i <= selectedDay; i++) {
        cumulativeValue += row.days[`day${i}`] || 0
      }

      // If showing percentage and cost is 0, return null for value
      if (showPercentage && !row.cost) {
        return {
          date: format(new Date(row.install_date), 'MM-dd'),
          install_date: new Date(row.install_date),
          value: null,
          cost: row.cost,
          rawValue: cumulativeValue,
          displayValue: '-',
        }
      }

      const value =
        showPercentage && row.cost
          ? (cumulativeValue / row.cost) * 100
          : cumulativeValue

      return {
        date: format(new Date(row.install_date), 'MM-dd'),
        install_date: new Date(row.install_date),
        value: value || 0,
        cost: row.cost,
        rawValue: cumulativeValue,
        displayValue: showPercentage
          ? `${((cumulativeValue / row.cost) * 100).toFixed(2)}%`
          : new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            }).format(cumulativeValue),
      }
    })
    .sort((a, b) => a.install_date - b.install_date)

  // Calculate Y axis range
  const values = chartData
    .map((d) => d.value)
    .filter((v) => v !== null && v !== undefined && v > 0)

  const minValue = Math.min(...values)
  const maxValue = Math.max(...values)
  const valueRange = maxValue - minValue

  // Calculate nice Y axis bounds with reasonable padding
  const padding = valueRange * 0.2 // 20% padding
  const yMaxRaw = maxValue + padding

  // Round to nice numbers based on the scale
  const yMax = showPercentage
    ? Math.ceil(yMaxRaw / 10) * 10 // Round to nearest 10% for percentage
    : Math.ceil(yMaxRaw / 100) * 100 // Round to nearest 100 for currency

  const chartConfig = {
    value: {
      label: `Day0-${selectedDay}`,
      color: 'hsl(var(--primary))',
    },
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>
          Day0-{selectedDay} {showPercentage ? 'ROAS' : 'Revenue'}
        </CardTitle>
      </CardHeader>
      <CardContent>
        <ChartContainer config={chartConfig}>
          <AreaChart
            data={chartData}
            margin={{
              top: 20,
              right: 20,
              left: 20,
              bottom: 20,
            }}
          >
            <CartesianGrid vertical={false} className="stroke-muted" />
            <XAxis
              dataKey="date"
              tickLine={false}
              axisLine={false}
              tickMargin={8}
              className="fill-muted-foreground text-sm"
            />
            <YAxis
              tickLine={false}
              axisLine={false}
              tickMargin={8}
              className="fill-muted-foreground text-sm"
              domain={[0, yMax]}
              tickCount={8}
              tickFormatter={(value) => {
                if (showPercentage) {
                  return `${value.toFixed(0)}%`
                }
                return new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                  notation: 'compact',
                  maximumFractionDigits: 1,
                }).format(value)
              }}
            />
            <ChartTooltip
              content={({ active, payload }) => {
                if (!active || !payload?.length) return null
                return (
                  <div className="rounded-lg border bg-background p-2 shadow-sm">
                    <div className="flex flex-col">
                      <span className="text-[0.70rem] uppercase text-muted-foreground">
                        {payload[0].payload.date}
                      </span>
                      <span className="font-bold">
                        {payload[0].payload.displayValue}
                      </span>
                    </div>
                  </div>
                )
              }}
            />
            {showPercentage && (
              <>
                <ReferenceLine
                  y={
                    colorRules.find((r) => r.day === `day${selectedDay}`)
                      ?.threshold ||
                    colorRules.find((r) => r.day === 'default').threshold
                  }
                  stroke="#22c55e"
                  strokeDasharray="3 3"
                  label={{
                    value: `${
                      colorRules.find((r) => r.day === `day${selectedDay}`)
                        ?.threshold ||
                      colorRules.find((r) => r.day === 'default').threshold
                    }%`,
                    position: 'right',
                    fill: '#22c55e',
                    className: 'text-sm',
                  }}
                />
                <ReferenceLine
                  y={
                    colorRules.find((r) => r.day === `day${selectedDay}`)
                      ?.lowThreshold ||
                    colorRules.find((r) => r.day === 'default').lowThreshold
                  }
                  stroke="#ef4444"
                  strokeDasharray="3 3"
                  label={{
                    value: `${
                      colorRules.find((r) => r.day === `day${selectedDay}`)
                        ?.lowThreshold ||
                      colorRules.find((r) => r.day === 'default').lowThreshold
                    }%`,
                    position: 'right',
                    fill: '#ef4444',
                    className: 'text-sm',
                  }}
                />
              </>
            )}
            <defs>
              <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
                <stop
                  offset="5%"
                  stopColor="hsl(var(--primary))"
                  stopOpacity={0.3}
                />
                <stop
                  offset="95%"
                  stopColor="hsl(var(--primary))"
                  stopOpacity={0}
                />
              </linearGradient>
            </defs>
            <Area
              type="monotone"
              dataKey="value"
              stroke="hsl(var(--primary))"
              strokeWidth={2}
              fill="url(#colorValue)"
              dot={{
                fill: 'hsl(var(--primary))',
                r: 4,
              }}
              activeDot={{
                r: 6,
                className: 'fill-primary',
              }}
            />
          </AreaChart>
        </ChartContainer>
      </CardContent>
      <CardFooter>
        <ScrollArea className="w-full">
          <Tabs
            value={selectedDay.toString()}
            onValueChange={(v) => setSelectedDay(parseInt(v))}
            className="w-full"
          >
            <TabsList className="inline-flex h-9 items-center justify-center rounded-lg bg-muted p-1 text-muted-foreground w-fit">
              {Array.from({ length: maxDay + 1 }, (_, i) => (
                <TabsTrigger
                  key={i}
                  value={i.toString()}
                  className="whitespace-nowrap"
                >
                  Day{i}
                </TabsTrigger>
              ))}
            </TabsList>
          </Tabs>
        </ScrollArea>
      </CardFooter>
    </Card>
  )
}

export default Day0Chart
