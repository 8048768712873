import { useEffect, useMemo, useState } from 'react'
import { DropdownCountry1 } from '@/components/DropdownCountry'
import useSWR from 'swr'
import MyLink from '@/components/zthd/my-link'
import { useUrlParamsStore } from '@/stores/url-params-store'
import AppSelect from '@/components/zthd/app-select'
import Loader from '@/components/zthd/Loader'
import { DatePicker } from '@/components/zthd/date-picker'
import { formatUSD } from '@/lib/format-utils'
import { Badge } from '@/components/ui/badge'
import { getStatusConfig } from '@/comm/campaign-status'
import { CustomBadge } from '@/components/zthd/badge'
import { cn } from '@/lib/utils'
import DetailAtDate from './detail.date'

const RevenueStructure = () => {
  const params = useUrlParamsStore((state) => state.params)
  const setParam = useUrlParamsStore((state) => state.setParam)
  const setParams = useUrlParamsStore((state) => state.setParams)
  const resetParams = useUrlParamsStore((state) => state.resetParams)
  const removeParam = useUrlParamsStore((state) => state.removeParam)

  const [selectedCampaign, setSelectedCampaign] = useState(null)

  // Update URL parameters
  const updateSearchParams = (key, value) => {
    if (value === 'total' || value === 'all') {
      removeParam(key)
    } else {
      setParam(key, value)
    }
  }

  useEffect(() => {
    const today = new Date()
    const year = today.getFullYear()
    const month = String(today.getMonth() + 1).padStart(2, '0')
    const day = String(today.getDate()).padStart(2, '0')
    
    resetParams()
    setParams({
      pid: '1',
      country: 'BR',
      revenueDate: `${year}-${month}-${day-2}`,
    })
  }, [setParams, resetParams])

  const { data, isLoading } = useSWR(['/troas/revenue-structure', params])

  const revenues = useMemo(() => data?.data ?? [], [data])
  const total = revenues.reduce((sum, item) => sum + item.revenue, 0)

  const statusRevenues = useMemo(() => {
    const result = {}
    revenues.forEach((item) => {
      const status = getStatusConfig(item.state)
      if (status) {
        result[status.label] = (result[status.label] || 0) + item.revenue
      }
    })
    return result
  }, [revenues])

  return (
    <div className="flex flex-col gap-4 py-4 sm:gap-2 sm:py-2">
      <div className="flex items-center gap-4 flex-wrap sm:gap-2 sm:py-2 xs:py-1 xs:gap-1">
        <AppSelect
          value={params.pid || ''}
          onValueChange={(value) => updateSearchParams('pid', value)}
        />
        <DropdownCountry1
          value={params.country || ''}
          update={(value) => updateSearchParams('country', value)}
        />
        <DatePicker
          date={params.revenueDate ? new Date(params.revenueDate) : null}
          onSelect={(date) => {
            const year = date.getFullYear()
            const month = String(date.getMonth() + 1).padStart(2, '0')
            const day = String(date.getDate()).padStart(2, '0')
            updateSearchParams('revenueDate', `${year}-${month}-${day}`)
          }}
        />
      </div>
      {isLoading && <Loader>Loading...</Loader>}
      <div className="flex">
        <div className="w-[900px] border rounded-lg">
          <div className="p-4 border-b">
            <div className="flex items-center justify-between mb-2">
              <h3 className="text-lg font-semibold">Revenue Structure</h3>
              <Badge variant="outline" className="text-base font-bold">
                Total: {formatUSD(total)}
              </Badge>
            </div>
            <div className="flex items-center gap-4 text-sm">
              {Object.entries(statusRevenues).map(([status, revenue]) => (
                <Badge key={status} variant="outline" size="sm">
                  {status}: {formatUSD(revenue)}
                </Badge>
              ))}
            </div>
          </div>

          {/* Header */}
          <div className="grid grid-cols-6 gap-4 p-3 bg-gray-50 text-sm font-medium text-gray-500 border-b">
            <div className="col-span-2">Campaign</div>
            <div>Media Source</div>
            <div>Status</div>
            <div className="text-right">Revenue</div>
            <div className="text-right">Percentage</div>
          </div>

          {/* List */}
          <div className="divide-y">
            {revenues.map((item) => (
              <RevenueItem
                key={item.campaign_id}
                item={item}
                total={total}
                isSelected={selectedCampaign?.campaign_id === item.campaign_id}
                setSelectedCampaign={setSelectedCampaign}
              />
            ))}
          </div>
        </div>
        {selectedCampaign && (
          <div className="flex flex-1">
            <DetailAtDate
              campaignId={selectedCampaign.campaign_id}
              date={params.revenueDate}
            />
          </div>
        )}
      </div>
    </div>
  )
}

const RevenueItem = ({ item, total, isSelected, setSelectedCampaign }) => {
  const status = getStatusConfig(item.state)
  return (
    <div
      className={cn(
        'grid grid-cols-6 gap-4 p-3 text-sm hover:bg-gray-50 group cursor-pointer',
        isSelected && 'bg-gray-300',
      )}
      onClick={() => setSelectedCampaign(item)}
    >
      <div className="col-span-2 min-w-0">
        <MyLink
          to={`/troas/campaigns/${item.campaign_id}`}
          className="font-medium text-gray-900 hover:underline truncate block"
        >
          {item.campaign_name}
        </MyLink>
      </div>
      <div className="text-gray-500 truncate">{item.media_source || '-'}</div>
      <div>
        {status && (
          <CustomBadge variant={status.variant} size="sm">
            {status.label}
          </CustomBadge>
        )}
      </div>
      <div className="text-right font-medium text-gray-900">
        {formatUSD(item.revenue)}
      </div>
      <div className="text-right font-medium text-gray-500">
        {total ? ((item.revenue / total) * 100).toFixed(1) : 0}%
      </div>
    </div>
  )
}

export default RevenueStructure
