import { Button } from '@/components/ui/button'
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { ChevronDownIcon } from '@radix-ui/react-icons'
import {
  GameApps,
  NekuApps,
  App0,
  Apps1,
  StickerApps,
  TotalsApp,
} from '@/comm/app'
import { usePackageName } from '@/features/roi/Home/DropdownPackage'
import { useEffect } from 'react'
import { create } from 'zustand'
import Selector from './zthd/Selector'

export const usePid = create((set) => ({
  value: 'total',
  update: (value) => set({ value }),
}))

const DropdownPid = ({ showLabel = true }) => {
  const value = usePid((state) => state.value)
  const update = usePid((state) => state.update)

  const updatePackageName = usePackageName((state) => state.update)

  const handleUpdate = (pid) => {
    update(pid)
    if (pid !== '14') {
      updatePackageName('') // reset package name when pid is not 14
    }
  }
  return (
    <Selector
      datas={App0}
      showLabel={showLabel}
      showCode
      labelName="Pid"
      value={value}
      update={handleUpdate}
    />
  )
}

export const usePid2 = create((set) => ({
  pids: ['total'],
  updateSingle: (pid) => set({ pids: [pid] }),
  update: (pid) =>
    set((state) => {
      const { pids } = state
      const pidsSet = new Set(pids) // 使用 Set 确保唯一性
      if (pid === 'total') {
        if (pidsSet.has(pid)) {
          pidsSet.clear()
        } else {
          pidsSet.clear()
          pidsSet.add(pid)
        }
      } else {
        pidsSet.delete('total')
        if (pid === 'total1') {
          StickerApps.forEach((p) => pidsSet.delete(p.code))
        } else if (pid === 'total2') {
          NekuApps.forEach((p) => pidsSet.delete(p.code))
        } else if (pid === 'total3') {
          GameApps.forEach((p) => pidsSet.delete(p.code))
        }

        if (pidsSet.has(pid)) {
          pidsSet.delete(pid)
        } else {
          pidsSet.add(pid)
          if (StickerApps.some((p) => p.code === pid)) {
            pidsSet.delete('total1')
          } else if (NekuApps.some((p) => p.code === pid)) {
            pidsSet.delete('total2')
          } else if (GameApps.some((p) => p.code === pid)) {
            pidsSet.delete('total3')
          }
        }
      }
      return { pids: Array.from(pidsSet) } // 转回数组
    }),
}))

export const isSelectedPid = (pid, pids = []) => {
  // If pids includes 'total', all pids are selected
  if (pids.includes('total')) {
    return true
  }

  // Handle total1 (Sticker apps)
  if (
    pids.includes('total1') &&
    StickerApps.some((p) => p.code === String(pid))
  ) {
    return true
  }

  // Handle total2 (Neku apps)
  if (pids.includes('total2') && NekuApps.some((p) => p.code === String(pid))) {
    return true
  }

  // Handle total3 (Game apps)
  if (pids.includes('total3') && GameApps.some((p) => p.code === String(pid))) {
    return true
  }

  // Check individual pid
  return pids.includes(String(pid))
}

export const DropdownPid3 = ({ showLabel = true }) => {
  const pids = usePid2((state) => state.pids)
  const update = usePid2((state) => state.updateSingle)
  const pid = pids.join(',') === '' ? 'total' : pids.join(',')

  const updatePackageName = usePackageName((state) => state.update)

  const handleUpdate = (pid) => {
    update(pid)
    if (pid !== '14') {
      updatePackageName('') // reset package name when pid is not 14
    }
  }
  return (
    <>
      <Selector
        datas={Apps1}
        showLabel={showLabel}
        showCode
        labelName="Pid"
        value={pid}
        update={handleUpdate}
      />
    </>
  )
}

export const DropdownPid2 = () => {
  const pids = usePid2((state) => state.pids)
  const update = usePid2((state) => state.update)
  const updatePackageName = usePackageName((state) => state.update)
  useEffect(() => {
    if (pids.join(',') !== '14') {
      updatePackageName('')
    }
  }, [pids, updatePackageName])

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline">
          Pids{pids.length > 0 && ':'}
          {pids.join(',')} <ChevronDownIcon className="ml-2 h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="max-h-80 overflow-y-auto">
        <DropdownMenuLabel>Total</DropdownMenuLabel>
        <DropdownMenuSeparator />
        {TotalsApp.map((p) => (
          <DropdownMenuCheckboxItem
            key={p.code}
            checked={pids.includes(p.code)}
            onCheckedChange={() => update(p.code)}
          >
            {p.name}
          </DropdownMenuCheckboxItem>
        ))}

        <DropdownMenuLabel>Sticker</DropdownMenuLabel>
        <DropdownMenuSeparator />

        {StickerApps.map((p) => {
          return (
            <DropdownMenuCheckboxItem
              key={p.code}
              className="capitalize"
              checked={pids.includes('total1') || pids.includes(p.code)}
              onCheckedChange={() => update(p.code)}
            >
              {p.code} - {p.name}
            </DropdownMenuCheckboxItem>
          )
        })}
        <DropdownMenuLabel>Neku</DropdownMenuLabel>
        <DropdownMenuSeparator />

        {NekuApps.map((p) => {
          return (
            <DropdownMenuCheckboxItem
              key={p.code}
              className="capitalize"
              checked={pids.includes('total2') || pids.includes(p.code)}
              onCheckedChange={() => update(p.code)}
            >
              {p.code} - {p.name}
            </DropdownMenuCheckboxItem>
          )
        })}
        <DropdownMenuLabel>Game</DropdownMenuLabel>
        <DropdownMenuSeparator />

        {GameApps.map((p) => {
          return (
            <DropdownMenuCheckboxItem
              key={p.code}
              className="capitalize"
              checked={pids.includes('total3') || pids.includes(p.code)}
              onCheckedChange={() => update(p.code)}
            >
              {p.code} - {p.name}
            </DropdownMenuCheckboxItem>
          )
        })}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export default DropdownPid
