import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useMemo, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { useLocation, useNavigate } from 'react-router-dom'
import { mutate } from 'swr'
import useSWR from 'swr'
import useSWRMutation from 'swr/mutation'

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form'
import { Button } from '@/components/ui/button'
import { Checkbox } from '@/components/ui/checkbox'
import { Input } from '@/components/ui/input'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { putFetcher } from '@/hooks/index'
import { formatUSD, formatPercent } from '@/lib/format-utils'
import { usePid2 } from '@/components/DropdownPid'
import { useCountry } from '@/components/DropdownCountry'
import DropdownYear, { useYearStore } from '@/components/DropdownYear'
import DataTableWithBorder from '@/components/zthd/data-table/data-table-with-border'
import {
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { useGoalEditorStore, useMonthDropdownStore } from './store'
import { Countries, Products } from '@/comm'
import { cn } from '@/lib/utils'

// Form schema for goal validation
const FormSchema = z.object({
  goal: z.preprocess(
    (val) => Number(val),
    z.number().min(0, {
      message: 'Goal value must be >= 0',
    }),
  ),
})

const MonthlyGoalsPage = () => {
  const location = useLocation()
  const navigate = useNavigate()
  
  // Parse query parameters
  const searchParams = new URLSearchParams(location.search)
  const year = searchParams.get('year') || new Date().getFullYear().toString()
  const month = searchParams.get('month') || String(new Date().getMonth() + 1).padStart(2, '0')
  
  // Set the year in the year dropdown store
  // Using single useEffect to avoid multiple state updates
  useEffect(() => {
    // Important: only update if needed to avoid infinite loops
    if (useYearStore.getState().value !== year) {
      useYearStore.getState().update(year)
    }
    
    if (useMonthDropdownStore.getState().value !== month) {
      useMonthDropdownStore.getState().setValue(month)
    }
  }, [year, month])
  
  return (
    <>
      <MonthlyGoalsHeader />
      <MonthlyGoalsTable year={year} month={month} />
      <GoalEditorDialog />
    </>
  )
}

const MonthDropdown = () => {
  const navigate = useNavigate()
  const value = useMonthDropdownStore((state) => state.value)
  const year = useYearStore((state) => state.value)
  
  const months = [
    { value: '01', label: 'January' },
    { value: '02', label: 'February' },
    { value: '03', label: 'March' },
    { value: '04', label: 'April' },
    { value: '05', label: 'May' },
    { value: '06', label: 'June' },
    { value: '07', label: 'July' },
    { value: '08', label: 'August' },
    { value: '09', label: 'September' },
    { value: '10', label: 'October' },
    { value: '11', label: 'November' },
    { value: '12', label: 'December' },
  ]
  
  // Using useCallback to stabilize this function
  const handleValueChange = useCallback((newValue) => {
    // Only navigate if the value has actually changed
    if (newValue !== value) {
      useMonthDropdownStore.getState().setValue(newValue)
      navigate(`/roi/monthly/goals?year=${year}&month=${newValue}`, { replace: true })
    }
  }, [navigate, year, value])
  
  return (
    <div className="w-32">
      <Select value={value} onValueChange={handleValueChange}>
        <SelectTrigger>
          <SelectValue placeholder="Select Month" />
        </SelectTrigger>
        <SelectContent>
          {months.map((month) => (
            <SelectItem key={month.value} value={month.value}>
              {month.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  )
}

const MonthlyGoalsHeader = () => {
  const { editing, toggleEditing } = useGoalEditorStore()
  const navigate = useNavigate()
  
  // Get current values from stores
  const year = useYearStore((state) => state.value)
  const month = useMonthDropdownStore((state) => state.value)
  
  // Handle year change - using useCallback to stabilize the function reference
  const handleYearChange = useCallback((newYear) => {
    if (newYear !== year) {
      navigate(`/roi/monthly/goalsline?year=${newYear}&month=${month}`, { replace: true })
    }
  }, [navigate, month, year])
  
  // Use the updated year setter - only once on mount
  useEffect(() => {
    // Don't modify the store if we're already handling the year in MonthlyGoalsPage
    // This prevents duplicate/cyclic updates
    const originalUpdate = useYearStore.getState().update
    
    if (!useYearStore.getState().onValueChange) {
      useYearStore.setState({
        onValueChange: handleYearChange,
      })
    }
    
    return () => {
      // Only reset if we set it
      if (useYearStore.getState().onValueChange === handleYearChange) {
        useYearStore.setState({
          onValueChange: undefined,
        })
      }
    }
  }, [handleYearChange])
  
  // Handle toggling edit mode
  const handleToggleEditing = useCallback(() => {
    // Ensure we're properly resetting everything when toggling editing mode
    if (!editing) {
      // If turning editing on, ensure monthId is reset
      toggleEditing()
    } else {
      // If turning editing off, use stopEditing to properly clear all edit state
      useGoalEditorStore.getState().stopEditing()
    }
  }, [editing, toggleEditing])
  
  return (
    <div className="flex items-center gap-4 py-4 sm:gap-2 sm:py-2 flex-wrap">
      <DropdownYear />
      <MonthDropdown />
      <div className="flex items-center space-x-2">
        <Checkbox
          id="enable-editor"
          checked={editing}
          onCheckedChange={handleToggleEditing}
        />
        <label
          htmlFor="enable-editor"
          className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
        >
          Enable editor model
        </label>
      </div>
    </div>
  )
}

// Country column definition
const countryColumn = {
  id: 'country',
  header: '#',
  accessorFn: (row) => row.country,
  cell: (row) => {
    const country = row.getValue()
    return <div>{country?.code}</div>
  },
}

// Total column definition - shows the sum of all products for each country
const totalColumn = {
  id: 'total-column',
  header: 'Total',
  accessorFn: (row) => {
    // Calculate the sum of all products for this country
    let total = 0
    Products.forEach(product => {
      total += row.goalsLine?.[product.code] || 0
    })
    return total
  },
  cell: (row) => {
    const total = row.getValue()
    return (
      <div className="text-right font-semibold">
        {formatUSD(total, 0, 0)}
      </div>
    )
  }
}

// Product/PID column creator - displays only monetary amount from goalsLine
const productColumn = (product, editing, startEditing) => ({
  id: `pid-goal-${product.code}`,
  header: `${product.name}`,
  accessorFn: (row) => {
    const lineValue = row.goalsLine?.[product.code] || 0
    return { 
      pid: product.code, 
      country: row.country.code, 
      lineValue
    }
  },
  cell: (row) => {
    const { pid, country, lineValue } = row.getValue()
    
    // Format the monetary amount (lineValue) 
    const formattedAmount = formatUSD(lineValue, 0, 0)
    
    // Check if this is the 'total' row or 'total' column - don't allow editing either
    const isTotal = country === 'total' || pid === 'total'
    
    const handleCellClick = () => {
      // Only allow editing if not 'total' row or column and editing mode is enabled
      if (!isTotal && editing) {
        // Pass the goalsLine value to the editor
        startEditing(pid, country, lineValue)
      }
    }
    
    return (
      <div 
        className={cn(
          "text-right",
          // Only show cursor pointer and hover effect if editable
          !isTotal && editing ? "cursor-pointer hover:bg-gray-100 p-1 rounded" : "",
          // Highlight total row or column with semi-bold font
          isTotal ? "font-semibold" : ""
        )}
        onClick={handleCellClick}
      >
        {formattedAmount}
      </div>
    )
  },
})

const MonthlyGoalsTable = ({ year, month }) => {
  // Get editing state
  const { editing, startEditing } = useGoalEditorStore()
  
  // Create month ID in YYYYMM format
  const monthId = `${year}${month}`
  
  // Fetch goalsline data for the selected month and year
  const { data: goalsLineData = {} } = useSWR([
    '/costs/goalsline',
    { year, month },
  ])
  
  // Filtered country list - without 'total' as we'll calculate it
  const countryCodes = useMemo(() => [
    'BR',
    'JP',
    'DE',
    'US',
    'MX',
    'TW',
    'IT',
    'KR',
    'ES',
    'HK',
    'SA',
    'TH',
    'CA',
    'FR',
    'SG',
    'ZA',
    'ID',
    'AR',
    'GB',
    'CO',
    'MY',
  ], [])
  
  // Calculate totals for each country and product
  const { tableData } = useMemo(() => {
    const countries = Countries.filter(c => countryCodes.includes(c.code))
    const tableData = []
    
    // First add all regular country rows
    countries.forEach(country => {
      const countryData = goalsLineData[country.code] || {}
      tableData.push({
        country,
        goalsLine: { ...countryData }
      })
    })
    
    // Now calculate totals for each product across all countries
    const productTotals = {}
    
    // Initialize all product totals to 0
    Products.forEach(product => {
      productTotals[product.code] = 0
    })
    
    // Sum up values for each product across all countries
    tableData.forEach(row => {
      Products.forEach(product => {
        const value = row.goalsLine[product.code] || 0
        productTotals[product.code] += value
      })
    })
    
    // Add the 'total' row as the first row
    tableData.unshift({
      country: { code: 'total', name: 'Total' },
      goalsLine: productTotals
    })
    
    return { tableData }
  }, [countryCodes, goalsLineData])
  
  // Create columns for each product
  const columns = useMemo(() => {
    // Define list of products to display, excluding any 'total' product
    const productsToDisplay = Products.filter(product => product.code !== 'total');
    
    const cols = [
      countryColumn,  // Country column (first column)
      totalColumn,    // Total column (second column)
    ]
    
    // Add columns for each product (excluding 'total')
    productsToDisplay.forEach(product => {
      cols.push(productColumn(product, editing, (pid, country, currentValue) => {
        startEditing(`${monthId}_${country}_${pid}`, currentValue)
      }))
    })
    
    return cols
  }, [monthId, editing, startEditing])
  
  // Create table with optimized settings
  const table = useReactTable({
    data: tableData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    // Add these options to optimize table performance
    enableSorting: false,
    enableColumnFilters: false,
    manualPagination: true,
    defaultPageSize: 100,
  })
  
  return (
    <div>
      <DataTableWithBorder table={table} />
    </div>
  )
}

const GoalEditorDialog = () => {
  // Get editing state
  const { editing, monthId, currentGoal, stopEditing } = useGoalEditorStore()
  
  // Parse the composite ID to get month, country and pid
  const [fullMonthId, country, pid] = useMemo(() => 
    monthId ? monthId.split('_') : ['', '', ''],
    [monthId]
  )
  
  // Extra safety check - immediately close dialog if trying to edit 'total'
  useEffect(() => {
    if (editing && (country === 'total' || pid === 'total')) {
      console.warn('Editing total row or column is not allowed')
      stopEditing()
    }
  }, [editing, country, pid, stopEditing])
  
  // Extract year and month from the monthId
  const year = useMemo(() => fullMonthId.substring(0, 4), [fullMonthId])
  const month = useMemo(() => fullMonthId.substring(4, 6), [fullMonthId])
  
  // Format month name for display
  const monthName = useMemo(() => {
    if (!month || !year) return ''
    const monthNumber = parseInt(month)
    const monthDate = new Date(parseInt(year), monthNumber - 1, 1)
    return monthDate.toLocaleString('default', { month: 'long' })
  }, [month, year])
  
  // Get product name from product code - memoized to reduce calculations
  const productInfo = useMemo(() => {
    const product = Products.find(p => p.code === pid)
    return {
      name: product ? product.name : pid
    }
  }, [pid])
  
  // Setup form validation - with better form resetting on goal change
  const form = useForm({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      goal: currentGoal,
    },
    mode: 'onChange' // Validate on change for immediate feedback
  })
  
  // Update form when goal value changes
  useEffect(() => {
    if (editing) {
      form.reset({ goal: currentGoal })
    }
  }, [editing, currentGoal, form])
  
  // Setup mutation for updating goal
  const { trigger, isMutating } = useSWRMutation('/costs/goalsline', putFetcher)
  
  // Handle form submission - with better error handling
  const onSubmit = useCallback(async (data) => {
    if (!monthId) return
    
    try {
      // Update goal value via the API
      await trigger({
        pid,
        country,
        month: fullMonthId,
        value: data.goal
      })
      
      // Only need to refresh goalsLine data since that's what we display
      mutate(['/costs/goalsline', { year, month }])
      
      // Close dialog and properly reset all state - using stopEditing instead of just closing
      stopEditing()
      
      // Keep editing mode enabled for convenience
      setTimeout(() => {
        // Re-enable editing mode without any selected cell
        useGoalEditorStore.getState().toggleEditing()
      }, 0)
    } catch (error) {
      console.error('Error updating goal:', error)
      // Could add toast/alert for error notification
    }
  }, [monthId, pid, country, fullMonthId, year, month, trigger, stopEditing])
  
  // Early return if not editing - prevents unnecessary rendering
  if (!editing || !monthId) return null
  
  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
        <h2 className="text-lg font-semibold mb-4">Edit Cost Goal</h2>
        
        <div className="flex flex-col gap-2 mb-4">
          <span>
            Month: <span className="text-destructive">{monthName} {year}</span>
          </span>
          <span>
            Product: <span className="text-destructive">{productInfo.name}</span>
          </span>
          <span>
            Country: <span className="text-destructive">{country}</span>
          </span>
          <span>
            Current Value: <span className="text-destructive">{formatUSD(currentGoal, 0, 0)}</span>
          </span>
        </div>
        
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <FormField
              control={form.control}
              name="goal"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      type="number"
                      placeholder="Enter new value"
                      {...field}
                      autoFocus
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            
            <div className="flex justify-end gap-2">
              <Button 
                type="button" 
                variant="outline" 
                onClick={stopEditing}
              >
                Cancel
              </Button>
              <Button 
                type="submit" 
                disabled={!form.formState.isValid || isMutating}
              >
                {isMutating ? 'Saving...' : 'Save Value'}
              </Button>
            </div>
          </form>
        </Form>
      </div>
    </div>
  )
}

export default MonthlyGoalsPage 