import { useMemo, useState } from 'react'
import useSWR from 'swr'
import { MyDatePicker, useDatePicker } from '@/components/MyDatePicker'
import { DropdownCountry1 } from '@/components/DropdownCountry'
import AppSelect from '@/components/zthd/app-select'
import { SummaryChart, RevenueChart, CostChart, InstallsChart } from './summary-chart'
import Loader from '@/components/zthd/Loader'
import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { Button } from '@/components/ui/button'

// 统一的store管理
const useSummaryStore = create(
  persist(
    (set) => ({
      // 国家选择
      country: 'BR',
      updateCountry: (value) =>
        set({ country: value === 'all' ? 'BR' : value }),

      // PID选择
      pid: '1',
      updatePid: (value) => set({ pid: value }),
    }),
    {
      name: 'summary-store',
      storage: createJSONStorage(() => localStorage),
    },
  ),
)

const SummaryPage = () => {
  const { country, updateCountry, pid, updatePid } = useSummaryStore()
  const [displayMode, setDisplayMode] = useState('dollar') // 'dollar' or 'percent'

  const { startDate, endDate } = useDatePicker((state) => ({
    startDate: state.startDate,
    endDate: state.endDate,
  }))

  const params = { pid, country, startDate, endDate }

  const { data, isLoading } = useSWR(['/troas/summary', params])

  const summaryData = useMemo(() => data?.data || [], [data])

  const updateSearchParams = (key, value) => {
    if (key === 'country') {
      updateCountry(value)
    } else if (key === 'pid') {
      updatePid(value)
    }
  }

  // State to manage active tab

  return (
    <div className="flex flex-col gap-4 py-4">
      <div className="flex items-center gap-4 flex-wrap">
        <AppSelect
          value={pid}
          onValueChange={(value) => updateSearchParams('pid', value)}
        />
        <DropdownCountry1
          value={country}
          update={(value) => updateSearchParams('country', value)}
        />
        <MyDatePicker />
        <div className="flex gap-1 ml-auto">
          <Button
            variant={displayMode === 'dollar' ? "default" : "outline"}
            size="sm"
            onClick={() => setDisplayMode('dollar')}
            className="h-8 w-8 p-0"
          >
            $
          </Button>
          <Button
            variant={displayMode === 'percent' ? "default" : "outline"}
            size="sm"
            onClick={() => setDisplayMode('percent')}
            className="h-8 w-8 p-0"
          >
            %
          </Button>
        </div>
      </div>
      {isLoading ? (
        <Loader>Loading...</Loader>
      ) : (
        <div className="container mx-auto mt-4">
          <Tabs defaultValue="summary" className="w-full">
            <TabsList className="grid grid-cols-4 w-[500px] mx-auto mb-4">
              <TabsTrigger value="summary">Summary</TabsTrigger>
              <TabsTrigger value="revenue">Revenue</TabsTrigger>
              <TabsTrigger value="cost">Cost</TabsTrigger>
              <TabsTrigger value="installs">Installs</TabsTrigger>
            </TabsList>
            <TabsContent value="summary">
              <SummaryChart data={summaryData} displayMode={displayMode} />
            </TabsContent>
            <TabsContent value="revenue">
              <RevenueChart data={summaryData} displayMode={displayMode} />
            </TabsContent>
            <TabsContent value="cost">
              <CostChart data={summaryData} displayMode={displayMode} />
            </TabsContent>
            <TabsContent value="installs">
              <InstallsChart data={summaryData} displayMode={displayMode} />
            </TabsContent>
          </Tabs>
        </div>
      )}
    </div>
  )
}

export default SummaryPage
