import { useState, useMemo, useEffect } from 'react'
import useSWR from 'swr'
import {
  IndexColumn,
  DateColumn,
  CampaignNameColumn,
  MediaSourceColumn,
  CountryColumn,
  ReasonColumn,
  CreatedAtColumn,
  OptColumn,
  CampaignStateColumn,
} from '@/components/roas/notes/columns'
import Loader from '@/components/zthd/Loader'
import {
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import DataTableWithBorder from '@/components/zthd/data-table/data-table-with-border'
import AppSelect from '@/components/zthd/app-select'
import { useUrlParamsStore } from '@/stores/url-params-store'
import { DropdownCountry1 } from '@/components/DropdownCountry'

const columns = [
  IndexColumn,
  DateColumn,
  CampaignNameColumn,
  CampaignStateColumn,
  MediaSourceColumn,
  CountryColumn,
  ReasonColumn,
  CreatedAtColumn,
  OptColumn,
]

const NotesPage = () => {
  const [page, setPage] = useState(1)
  const pageSize = 20
  const params = useUrlParamsStore((state) => state.params)
  const setParam = useUrlParamsStore((state) => state.setParam)
  const resetParams = useUrlParamsStore((state) => state.resetParams)
  const removeParam = useUrlParamsStore((state) => state.removeParam)

  const { data, isLoading } = useSWR([`/troas/notes`, params])
  const notes = useMemo(() => data?.data?.notes || [], [data])
  const [sorting, setSorting] = useState([{ id: 'createdAt', desc: true }])

  // 更新 URL 参数的函数
  const updateSearchParams = (key, value) => {
    if (value === 'total' || value === 'all') {
      removeParam(key)
    } else {
      setParam(key, value)
    }
  }

  useEffect(() => {
    resetParams()
  }, [resetParams])

  const table = useReactTable({
    data: notes,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    state: {
      sorting,
    },
  })

  if (isLoading) return <Loader />

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center gap-4">
        <AppSelect
          value={params.pid || ''}
          onValueChange={(value) => updateSearchParams('pid', value)}
        />
        <DropdownCountry1
          value={params.country || ''}
          update={(value) => updateSearchParams('country', value)}
        />
      </div>
      <div className="container mx-auto">
        <DataTableWithBorder table={table} />
      </div>
    </div>
  )
}

export default NotesPage
