import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import useSWRMutation from 'swr/mutation'
import { mutateRequest, postFetcher } from '@/hooks'
import { toast } from 'sonner'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogDescription,
} from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import { DialogClose } from '@radix-ui/react-dialog'
import { Form } from '@/components/ui/form'
import { MyFromTextarea } from '@/components/zthd/MyFormField'

const FormSchema = z.object({
  reason: z.string().min(2, {
    message: 'note must be at least 2 characters.',
  }),
})
export function CreateNote({ cid, date }) {
  const { trigger } = useSWRMutation(`/campaigns/${cid}/notes`, postFetcher)

  const form = useForm({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      reason: '',
    },
  })

  const handleSubmit = async (data) => {
    toast('You submitted the following values:', {
      description: (
        <pre className="mt-2 w-[340px] rounded-md bg-slate-950 p-4">
          <code className="text-white">{JSON.stringify(data, null, 2)}</code>
        </pre>
      ),
    })

    if (!data.reason) {
      return
    }

    const body = { reason: data.reason, date }
    await trigger(body)
    mutateRequest(`/campaigns/${cid}/notes`)

    toast('Note created')
  }

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="outline">Create note</Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Create note</DialogTitle>
          <DialogDescription />
          <div className="flex flex-col gap-2">
            <span>
              Date:<span className="text-destructive">{date}</span>
            </span>
            <span>
              Campaign:<span className="text-destructive">{cid}</span>
            </span>
          </div>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(handleSubmit)}
              className="space-y-6"
            >
              <MyFromTextarea form={form} name="reason" placeholder="note" />
              <DialogClose asChild>
                <Button type="submit">Submit</Button>
              </DialogClose>
            </form>
          </Form>
        </div>
      </DialogContent>
    </Dialog>
  )
}
