import dayjs from 'dayjs'

import { Button } from '@/components/ui/button'
import { Checkbox } from '@/components/ui/checkbox'
import { createColumnVisibilityStore } from '@/components/zthd/data-table'
import { DataTableViewOptions } from '@/components/zthd/data-table-view-options'
import DataTableWithBorder from '@/components/zthd/data-table/data-table-with-border'
import {
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { useColumnFilters } from '@/components/data-table-selectors'
import DropdownCountry, { useCountry } from '@/components/DropdownCountry'
import { DropdownPid2, usePid2 } from '@/components/DropdownPid'
import DropdownYear, { useYearStore } from '@/components/DropdownYear'
import {
  useReadableCost,
  useReadableEr,
  useReadableRevenue,
} from '@/hooks/auth'
import useSWR from 'swr'
import { create, useStore as useZustandStore } from 'zustand'
import {
  columnCPI,
  columnCosts,
  columnDate,
  columnEr,
  columnGoals,
  columnInstalls,
  columnProfit,
  columnRevenue,
} from './columns'
import { Settings } from 'lucide-react'
import { useNavigate } from 'react-router-dom'

import { useMemo } from 'react'
import { TotalsApp } from '@/comm/app'
import { formatUSD, formatPercent } from '@/lib/format-utils'
import { cn } from '@/lib/utils'

const useColumnVisibilityStore = createColumnVisibilityStore({}, 'monthly')

// Function to get days in month
const getDaysInMonth = (year, month) => {
  return new Date(year, month, 0).getDate();
}

// Function to generate a range of months
const getMonthRange = (year, monthsCount = 12) => {
  const months = []
  const startDate = dayjs(`${year}-01-01`)
  
  // Convert monthsCount to number to ensure it's treated as a number
  const count = parseInt(monthsCount, 10)
  
  for (let i = 0; i < count; i++) {
    const currentDate = startDate.add(i, 'month')
    const monthId = currentDate.format('YYYYMM')
    const monthYear = parseInt(year)
    const monthNum = parseInt(currentDate.format('MM'))
    
    months.push({
      id: monthId,
      name: currentDate.format('MMMM YYYY'),
      daysInMonth: getDaysInMonth(monthYear, monthNum)
    })
  }
  
  return months
}

const MonthonMonth = () => {
  return (
    <>
      <Form />
      <RenderTable />
    </>
  )
}

const Form = () => {
  const navigate = useNavigate()
  const year = useYearStore((state) => state.value)
  
  return (
    <div className="flex items-center gap-4 py-4 sm:gap-2 sm:py-2 flex-wrap xs:gap-1 xs:py-1">
      <DropdownPid2 />
      <DropdownCountry />
      <DropdownYear />
    </div>
  )
}

// Modified date column with the settings button
const createDateColumn = () => ({
  id: 'month',
  accessorFn: (row) => row.month,
  header: 'Month',
  cell: ({ row, getValue }) => {
    const navigate = useNavigate()
    const month = getValue()
    const { monthId } = row.original
    const year = useYearStore((state) => state.value)
    
    const handleGoalSettings = () => {
      const month = monthId.substring(4, 6) // Extract month from YYYYMM format
      navigate(`/roi/monthly/goals?year=${year}&month=${month}`)
    }
    
    return (
      <div className="flex items-center space-x-2 text-left">
        <div>{month}</div>
        <Button 
          variant="ghost" 
          size="icon" 
          onClick={handleGoalSettings}
          title="Goal Settings"
          className="h-7 w-7 p-0"
        >
          <Settings className="h-4 w-4" />
        </Button>
      </div>
    )
  },
})

// Create a simplified version of the Goals column without edit functionality
const createSimpleGoalsColumn = () => ({
  id: 'goals',
  accessorFn: (row) => {
    const goals = row.goals || 0
    return { 
      goals,
      costs: row.costs
    }
  },
  header: 'Goals Line',
  cell: (row) => {
    const { goals, costs } = row.getValue()
    const formatted = formatUSD(goals, 0, 0)
    const goalPercent = costs > 0 && goals > 0 ? costs / goals : 0
    
    return (
      <div className="flex items-center space-x-2 w-full">
        <div className="text-right">{formatted}</div>
        <div className="flex-1 h-5 bg-muted rounded-sm overflow-hidden">
          <div 
            className={cn(
              "h-full",
              goalPercent >= 1 ? "bg-green-500" : "bg-destructive"
            )}
            style={{ width: `${Math.min(goalPercent * 100, 100)}%` }}
          />
        </div>
        <div className={cn(
          "text-right min-w-16",
          goalPercent >= 1 ? "text-green-500" : "text-destructive"
        )}>
          {formatPercent(goalPercent)}
        </div>
      </div>
    )
  },
})

// Custom skeleton component for the table (without using shadcn Skeleton)
const TableSkeleton = () => {
  return (
    <div className="space-y-4">
      <div className="flex items-center gap-4">
        {[1, 2, 3].map((i) => (
          <div key={i} className="h-8 w-24 bg-muted animate-pulse rounded-md" />
        ))}
        <div className="h-8 w-20 ml-auto bg-muted animate-pulse rounded-md" />
      </div>
      <div className="border rounded-md">
        <div className="h-10 border-b bg-muted/50 flex items-center">
          {[1, 2, 3, 4, 5, 6].map((i) => (
            <div key={i} className="h-4 mx-4 flex-1 bg-muted animate-pulse rounded-md" />
          ))}
        </div>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((i) => (
          <div key={i} className="h-12 border-b flex items-center">
            {[1, 2, 3, 4, 5, 6].map((j) => (
              <div key={j} className="h-4 mx-4 flex-1 bg-muted animate-pulse rounded-md" />
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}

const RenderTable = () => {
  const pids = usePid2((state) => state.pids)
  const country = useCountry((state) => state.value)
  const year = useYearStore((state) => state.value)

  const pid = pids.join(',')

  const readableCost = useReadableCost(pid, country)
  const readableRevenue = useReadableRevenue(pid, country)
  const readableEr = useReadableEr(pid, country)

  const { data: baselines, isLoading: isBaselineLoading } = useSWR([readableEr ? '/ers/baselines' : null])
  const erBaseLine = baselines?.[country]?.[pid] ?? 0.35

  const { columnVisibility, setColumnVisibility } = useColumnVisibilityStore()
  const columnFilters = useColumnFilters((state) => state.columnFilters)

  const { data: ers, isLoading: isErsLoading } = useSWR([
    '/ers/monthly',
    { pid, country, year },
  ])

  const { data: revenues, mutate: mutateR, isLoading: isRevenuesLoading } = useSWR([
    readableRevenue ? '/revenues/monthly' : null,
    { pid, country, year },
  ])

  const { data: costs, mutate: mutateC, isLoading: isCostsLoading } = useSWR([
    readableCost ? '/costs/monthly' : null,
    { pid, country, year },
  ])

  // Fetch goals data
  const { data: goals, mutate: mutateG, isLoading: isGoalsLoading } = useSWR([
    '/costs/goalsline/summary',
    { pid, country, year },
  ])

  // Determine if any data is still loading
  const isLoading = 
    (readableEr && isBaselineLoading) ||
    isErsLoading || 
    (readableRevenue && isRevenuesLoading) || 
    (readableCost && isCostsLoading) || 
    isGoalsLoading;

  const data = useMemo(() => {
    if (isLoading || !costs || !revenues) return []
    
    const months = getMonthRange(year)
    
    return months.map((month, idx) => {
      // Find the corresponding data for the current month
      const currentCost = costs.find(c => c.id === month.id) || { costs: 0, installs: 0, value: 0 }
      const currentRevenue = revenues.find(r => r.id === month.id) || { total: 0 }
      const currentEr = ers?.[month.id] || 0
      const currentGoal = goals?.[month.id] || 0
      
      // Find previous month data for comparison (or null if it's January)
      const prevMonth = idx > 0 ? months[idx - 1] : null
      const prevMonthCost = prevMonth 
        ? costs.find(c => c.id === prevMonth.id) || { costs: 0, installs: 0, value: 0 }
        : null
      const prevMonthRevenue = prevMonth 
        ? revenues.find(r => r.id === prevMonth.id) || { total: 0 }
        : null
      const prevMonthEr = prevMonth ? ers?.[prevMonth.id] || 0 : null
      const prevMonthGoal = prevMonth && goals?.[prevMonth.id] || 0
      
      // Extract values from the data
      const costsValue = currentCost.costs || 0
      const installs = currentCost.installs || 0
      const revenueValue = currentRevenue.total || 0
      
      // Calculate derived values
      const profit = revenueValue - costsValue
      const cpi = installs > 0 ? costsValue / installs : 0
      
      // Previous month values (if available)
      const prevCostsValue = prevMonthCost?.costs || 0
      const prevInstalls = prevMonthCost?.installs || 0
      const prevRevenueValue = prevMonthRevenue?.total || 0
      const prevProfit = prevRevenueValue - prevCostsValue
      const prevCpi = prevInstalls > 0 ? prevCostsValue / prevInstalls : 0
      const prevGoal = prevMonthGoal || 0
      
      return {
        month: month.name,
        monthId: month.id,
        daysInMonth: month.daysInMonth,
        
        // Add pid and country for API calls
        pid,
        country,
        
        // Add refresh function
        refresh: mutateG,
        
        // Current month values
        costs: costsValue,
        revenues: revenueValue,
        profit,
        installs,
        cpi,
        er: currentEr,
        goals: currentGoal,
        
        // Previous month values for comparison
        prevCosts: prevCostsValue,
        prevRevenues: prevRevenueValue,
        prevProfit,
        prevInstalls,
        prevCpi,
        prevEr: prevMonthEr,
        prevGoals: prevGoal,
        
        // Growth (compared to previous month)
        growth: prevRevenueValue ? (revenueValue - prevRevenueValue) / prevRevenueValue : 0
      }
    })
  }, [year, costs, revenues, ers, goals, isLoading])

  const columns = useMemo(() => {
    const rows = []
    // Use the modified date column instead of the original one
    rows.push(createDateColumn())
    
    if (readableCost) {
      rows.push(columnInstalls)
    }
    if (readableRevenue) {
      rows.push(columnRevenue)
    }
    if (readableCost) {
      rows.push(columnCosts)
    }
    if (readableRevenue && readableCost) {
      rows.push(columnProfit)
    }
    rows.push(columnEr(erBaseLine))

    if (readableCost) {
      rows.push(columnCPI)
    }
    
    // Add the simplified Goals column without edit functionality
    rows.push(createSimpleGoalsColumn())

    return rows
  }, [readableRevenue, readableCost, erBaseLine])

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    enableMultiRowSelection: true,
    state: {
      columnFilters,
      columnVisibility,
    },
  })

  const refresh = () => [mutateR(), mutateC(), mutateG()]

  return (
    <>
      <div className="flex items-center gap-4 py-4 sm:gap-2 sm:py-2 flex-wrap">
        <ToggleShowLine num={1} text="monthly sum" />
        <ToggleShowLine num={2} text="daily avg" />
        <ToggleShowLine num={3} text="monthly growth" />
        <Button onClick={refresh}>Refresh</Button>
        <DataTableViewOptions table={table} />
      </div>
      
      {isLoading ? (
        <TableSkeleton />
      ) : (
        <DataTableWithBorder table={table} />
      )}
    </>
  )
}

export const useShowDataStore = create((set) => ({
  line1: true,
  line2: true,
  line3: true,
  toggleLine: (num) =>
    set((state) => ({ [`line${num}`]: !state[`line${num}`] })),
}))

const ToggleShowLine = ({ num, text }) => {
  const value = useShowDataStore((state) => state?.[`line${num}`])
  const toggleLine = useShowDataStore((state) => state.toggleLine)

  const handleChange = () => {
    toggleLine(num)
  }

  return (
    <div className="flex items-center space-x-2">
      <Checkbox
        id={`line${num}`}
        checked={value}
        onCheckedChange={handleChange}
      />
      <label
        htmlFor={`line${num}`}
        className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
      >
        {text}
      </label>
    </div>
  )
}

export default MonthonMonth