import { Button } from '@/components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { CustomBadge } from '@/components/zthd/badge'
import { campaignStatusMap, getStatusConfig } from '@/comm/campaign-status'
import useSWRMutation from 'swr/mutation'
import { mutateRequest, putFetcher } from '@/hooks'
import Loader from '@/components/zthd/Loader'

const StatusCell = ({ row }) => {
  const campaign = row.original
  const state = campaign.state
  const status = getStatusConfig(state)

  const { trigger, isMutating } = useSWRMutation(
    `/campaigns/${campaign.id}`,
    putFetcher,
  )

  const handleStatusChange = async (newState) => {
    await trigger({ state: newState })
    campaign.state = newState
    mutateRequest(`/campaigns`)
  }

  if (isMutating) {
    return <Loader>Mutating...</Loader>
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild disabled={isMutating}>
        <Button variant="ghost" className="p-0 h-auto">
          <CustomBadge variant={status.variant} size="sm">
            {status.label}
          </CustomBadge>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuLabel>Set Status</DropdownMenuLabel>
        {Object.entries(campaignStatusMap).map(([key, { label, variant }]) => (
          <DropdownMenuItem
            key={key}
            onClick={() => handleStatusChange(Number(key))}
            disabled={isMutating}
          >
            <CustomBadge variant={variant} size="sm">
              {label}
            </CustomBadge>
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export default StatusCell
