import { useMemo, useState } from 'react'
import DataTableWithBorder from '@/components/zthd/data-table/data-table-with-border'
import {
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { getColumns } from './columns'
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { useRoasStore } from '@/stores/roas'
import { CreateNote } from './notes-create'
import dayjs from 'dayjs'

const getYesterday = () => {
  return dayjs().subtract(1, 'day').format('YYYY-MM-DD')
}

const DailyRoasTable = ({ roas, revisedRoas }) => {
  const [sorting, setSorting] = useState([{ id: 'install_date', desc: true }])

  const yesterday = getYesterday()

  const columns = useMemo(() => {
    return getColumns(roas.campaignId, roas.summary?.maxDay ?? 7, revisedRoas)
  }, [roas.campaignId, roas.summary?.maxDay, revisedRoas])

  const table = useReactTable({
    data: roas.dailyData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    state: {
      sorting,
    },
  })

  return (
    <>
      <div className="flex flex-wrap items-center gap-4 mt-2">
        <CreateNote cid={roas.campaignId} date={yesterday} />
        <Toggles />
      </div>
      <div className="rounded-md border mt-2">
        <DataTableWithBorder table={table} />
      </div>
    </>
  )
}

const Toggles = () => {
  const { displayMode, dayMode, setDisplayMode, setDayMode } = useRoasStore()
  return (
    <div className="flex gap-2 ml-auto">
      <Tabs value={displayMode} onValueChange={setDisplayMode}>
        <TabsList>
          <TabsTrigger value="amount">$</TabsTrigger>
          <TabsTrigger value="percentage">%</TabsTrigger>
        </TabsList>
      </Tabs>
      <Tabs value={dayMode} onValueChange={setDayMode}>
        <TabsList>
          <TabsTrigger value="daily">Daily</TabsTrigger>
          <TabsTrigger value="cumulative">Cumulative</TabsTrigger>
        </TabsList>
      </Tabs>
    </div>
  )
}

export default DailyRoasTable
