import React from 'react'
import { create } from 'zustand'
import Selector from './zthd/Selector'

// Get current year
const currentYear = new Date().getFullYear()

// Generate years array from 2023 to current year + 1
const generateYears = () => {
  const years = []
  for (let year = 2023; year <= currentYear + 1; year++) {
    years.push({ code: year.toString(), name: year.toString() })
  }
  return years
}

const years = generateYears()

export const useYearStore = create((set) => ({
  value: currentYear.toString(), // Default to current year
  update: (value) => set({ value }),
}))

const DropdownYear = () => {
  const value = useYearStore((state) => state.value)
  const update = useYearStore((state) => state.update)
  
  return (
    <Selector
      datas={years}
      showLabel
      labelName="Year"
      value={value}
      update={update}
    />
  )
}

export default DropdownYear 