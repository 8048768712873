import { useState } from 'react'
import DataTableWithBorder from '@/components/zthd/data-table/data-table-with-border'
import {
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { columns } from '@/components/roas/notes/columns'

const DailyNotesTable = ({ notes }) => {
  const [sorting, setSorting] = useState([{ id: 'date', desc: true }])
  const table = useReactTable({
    data: notes,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    state: {
      sorting,
    },
  })

  return (
    <div className="rounded-md border">
      <DataTableWithBorder table={table} />
    </div>
  )
}

export default DailyNotesTable
