import { formatUSD, formatPercent } from '@/lib/format-utils'
import { useRoasStore } from '@/stores/roas'
import { getColorByValue } from '../../lib/color-rules'
import InstallDateCell from '@/components/roas/columns/install-date-cell'

const MoneyCell = ({ value }) => {
  if (!value) {
    return (
      <div className="text-right">
        <span className="text-muted-foreground">-</span>
      </div>
    )
  }
  return <div className="text-right">{formatUSD(value)}</div>
}

const CostCell = ({ cost, totalCost }) => {
  return (
    <div className="flex flex-col">
      <div className="text-right">{formatUSD(cost)}</div>
      <div className="text-right text-xs text-gray-500">{formatUSD(totalCost)}/{formatPercent(cost/totalCost, 1, 1)}</div>
    </div>
  )
}

const InstallsCell = ({ installs, totalInstalls }) => {
  return (
    <div className="flex flex-col">
      <div className="text-right">{installs}</div>
      <div className="text-right text-xs text-gray-500">{totalInstalls}/{formatPercent(installs/totalInstalls, 1, 1)}</div>
    </div>
  )
}

const ProfitCell = ({ revenue, cost }) => {
  if (!revenue || !cost) {
    return (
      <div className="text-right">
        <span className="text-muted-foreground">-</span>
      </div>
    )
  }
  const profit = revenue - cost
  const colorClass = profit >= 0 ? 'text-green-600' : 'text-red-600'
  return <div className={`text-right ${colorClass}`}>{formatUSD(profit)}</div>
}

const CpiCell = ({ row }) => {
  const cost = row.original.cost
  const installs = row.original.installs
  const cpi = cost / installs
  if (!cpi) {
    return <div className="text-right">-</div>
  }
  return <div className="text-right">{formatUSD(cpi, 4, 4)}</div>
}

const getRoasColor = (value, roasline, roiline) => {
  if (!value) return ''
  if (value/100 >= roasline) return 'text-green-600'
  if (value/100 <= roiline) return 'text-red-600'
  return ''
}

const RoasCell = ({ value, roasline, roiline }) => {
  if (!value) {
    return (
      <div className="text-right">
        <span className="text-muted-foreground">-</span>
      </div>
    )
  }
  const colorClass = getRoasColor(value, roasline, roiline)
  return (
    <div className="flex flex-col">
      <div className={`text-right font-bold ${colorClass}`}>
        {formatPercent(value / 100, 2, 2)}
      </div>
      {roasline && (
        <div className="text-right text-xs text-gray-500">
          {formatPercent(roasline, 2, 2)}
        </div>
      )}
      {roiline && (
        <div className="text-right text-xs text-red-600">
          {formatPercent(roiline, 2, 2)}
        </div>
      )}
    </div>
  )
}

const DayCell = ({ dayIndex, row }) => {
  const { displayMode, dayMode } = useRoasStore()
  const showPercentage = displayMode === 'percentage'
  const showCumulative = dayMode === 'cumulative'

  const days = row.original.days || {}
  const cost = row.original.cost
  const revenueDays = row.original.revenueDays || {}

  const install_date = row.original.install_date
  if (install_date === '2025-01-03') {
    // console.log(days)
  }
  let value = 0
  let revenueValue = 0
  if (showCumulative) {
    if (!days[`day${dayIndex}`]) {
      return
    }

    Object.keys(days).forEach((key, index) => {
      if (index > dayIndex) {
        return null
      }

      const dayValue = days[key]
      if (dayValue) {
        value += dayValue
      } else {
        value += 0
      }
    })
  } else {
    value = days[`day${dayIndex}`] || 0
    revenueValue = revenueDays[`day${dayIndex}`] || 0
  }

  if (!value) {
    return null
  }

  const colorClass = getColorByValue(value, cost, dayIndex)

  if (showPercentage) {
    if (!cost) {
      return (
        <div className="text-right">
          <span className="text-muted-foreground">-</span>
        </div>
      )
    }

    return (
      <div className={`text-right ${colorClass}`}>
        {formatPercent(value / cost, 1, 1)}
      </div>
    )
  }

  // return <div className={`text-right ${colorClass}`}>{formatUSD(value)}</div>

  return (<div className="flex flex-col">
      <div className="text-right">{formatUSD(value)}</div>
      {/* <div className="text-right text-xs text-gray-500">{formatUSD(revenueValue)}</div> */}
      <div className="text-right text-xs text-gray-500">{formatUSD(revenueValue)}/{formatPercent(value/revenueValue, 1, 1)}</div>
    </div>)
}

const DayRetCell = ({ dayIndex, row }) => {
  const installDays = row.original.installDays || {}
  const installs = row.original.installs

  const install_date = row.original.install_date
  if (install_date === '2025-01-03') {
    // console.log(days)
  }
  let value = 0
  value = installDays[`day${dayIndex}`] || 0

  if (!value) {
    return null
  }

  return <div className={`text-right`}>{formatPercent(value / installs)}</div>
}

const InstallDateColumn = (campaignId) => ({
  accessorKey: 'install_date',
  header: ({ column }) => (
    <button
      className="flex items-center gap-1 truncate"
      onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
    >
      Install Date
    </button>
  ),
  cell: ({ row }) => <InstallDateCell row={row} campaignId={campaignId} />,
  sortingFn: 'datetime',
  enableSorting: true,
})

const getColumns = (campaignId, maxDay = 7, revisedRoas) => {
  return [
    {
      id: 'index',
      header: () => <div className="text-right">#</div>,
      cell: ({ row }) => <div className="text-right">{row.index + 1}</div>,
    },
    InstallDateColumn(campaignId),
    {
      accessorKey: 'revenue',
      header: () => <div className="text-right">Revenue</div>,
      cell: ({ row }) => <MoneyCell value={row.original.revenue} />,
    },
    {
      accessorKey: 'cost',
      header: () => <div className="text-right">Cost</div>,
      cell: ({ row }) => <CostCell cost={row.original.cost} totalCost={row.original.totalCampCost}/>,
    },
    {
      id: 'profit',
      header: () => <div className="text-right">Profit</div>,
      cell: ({ row }) => (
        <ProfitCell revenue={row.original.revenue} cost={row.original.cost} />
      ),
    },
    {
      id: 'installs',
      header: () => <div className="text-right">Installs</div>,
      cell: ({ row }) => (
        <InstallsCell 
          installs={row.original.installs} 
          totalInstalls={row.original.totalCampInstalls}
        />
      ),
    },
    {
      id: 'cpi',
      header: () => <div className="text-right">CPI</div>,
      cell: ({ row }) => <CpiCell row={row} />,
    },
    {
      accessorKey: 'roas',
      header: () => (
        <div className="flex flex-col">
          <div className="text-right font-bold">ROAS</div>
          {revisedRoas?.summary?.roasline && (
            <div className="text-right text-xs text-gray-500">
              {formatPercent(revisedRoas.summary.roasline, 2, 2)}
            </div>
          )}
          {revisedRoas?.summary?.roiline && (
            <div className="text-right text-xs text-red-600">
              {formatPercent(revisedRoas.summary.roiline, 2, 2)}
            </div>
          )}
        </div>
      ),
      cell: ({ row }) => {
        // Find matching date in revisedRoas.dailyData if available
        const matchingDay = revisedRoas?.dailyData?.find(
          item => item.date === row.original.install_date
        );
        
        return <RoasCell 
          value={row.original.roas} 
          roasline={matchingDay?.roasline} 
          roiline={matchingDay?.roiline} 
        />;
      },
    },
    {
      id: 'spacer',
      header: () => <div className="w-2"></div>,
      cell: () => <div className="w-2"></div>,
    },
    ...Array.from({ length: maxDay + 1 }, (_, i) => ({
      id: `day${i}`,
      cell: ({ row }) => <DayCell dayIndex={i} row={row} />,
      header: () => <div className="text-right">Day {i}</div>,
    })),
  ]
}

const getRetColumns = (campaignId, maxDay = 7, revisedRoas) => {
  return [
    InstallDateColumn(campaignId),
    {
      accessorKey: 'revenue',
      header: () => <div className="text-right">Revenue</div>,
      cell: ({ row }) => <MoneyCell value={row.original.revenue} />,
    },
    {
      accessorKey: 'cost',
      header: () => <div className="text-right">Cost</div>,
      cell: ({ row }) => <MoneyCell value={row.original.cost} />,
    },
    {
      id: 'profit',
      header: () => <div className="text-right">Profit</div>,
      cell: ({ row }) => (
        <ProfitCell revenue={row.original.revenue} cost={row.original.cost} />
      ),
    },
    {
      id: 'installs',
      header: () => <div className="text-right">Installs</div>,
      cell: ({ row }) => (
        <div className="text-right">{row.original.installs}</div>
      ),
    },
    {
      id: 'cpi',
      header: () => <div className="text-right">CPI</div>,
      cell: ({ row }) => <CpiCell row={row} />,
    },
    {
      accessorKey: 'roas',
      header: () => (
        <div className="flex flex-col">
          <div className="text-right font-bold">ROAS</div>
          {revisedRoas?.summary?.roasline && (
            <div className="text-right text-xs text-gray-500">
              {formatPercent(revisedRoas.summary.roasline, 2, 2)}
            </div>
          )}
          {revisedRoas?.summary?.roiline && (
            <div className="text-right text-xs text-red-600">
              {formatPercent(revisedRoas.summary.roiline, 2, 2)}
            </div>
          )}
        </div>
      ),
      cell: ({ row }) => {
        // Find matching date in revisedRoas.dailyData if available
        const matchingDay = revisedRoas?.dailyData?.find(
          item => item.date === row.original.install_date
        );
        
        return <RoasCell 
          value={row.original.roas} 
          roasline={matchingDay?.roasline} 
          roiline={matchingDay?.roiline} 
        />;
      },
    },
    {
      id: 'spacer',
      header: () => <div className="w-2"></div>,
      cell: () => <div className="w-2"></div>,
    },
    ...Array.from({ length: maxDay + 1 }, (_, i) => ({
      id: `day${i}`,
      cell: ({ row }) => <DayRetCell dayIndex={i} row={row} />,
      header: () => <div className="text-right">Day {i}</div>,
    })),
  ]
}

export { getColumns, getRetColumns }
