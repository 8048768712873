import { getWeek } from '@/lib/date-utils'
import DateCell from './date-cell'

const columnDate = (pid) => ({
  id: 'date',
  header: 'Date',
  cell: ({ row }) => <DateCell row={row} pid={pid} />,
  enableHiding: false,
  filterFn: (row, columnId, filterValue) => {
    const data = row.original
    const date = data.date
    const [weekIndex] = getWeek(date)
    return weekIndex === filterValue
  },
})

export default columnDate
