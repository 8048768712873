import { DropdownMenuItem } from '@/components/ui/dropdown-menu'
import {
  idxColumn,
  nameColumn,
  mediaSourceColumn,
  countryColumn,
  ownerColumn,
  pidColumn,
  statusColumn,
  createActionsColumn,
} from '../columns/common-columns'
import { CopyItem } from '@/components/zthd/DropdownMenu'
import { formatPercent, formatUSD } from '@/lib/format-utils'

const actionsMenuItems = (row) => [
  <CopyItem data={row.original.id} key="copy" />,
  <DropdownMenuItem key="view">View Details</DropdownMenuItem>,
  <DropdownMenuItem key="edit">Edit</DropdownMenuItem>,
]

const totalCostColumn = {
  accessorKey: 'totalCost',
  header: 'Cost',
  cell: ({ row }) => formatUSD(row.original.totalCost),
  sortingFn: 'basic',
  sortDescFirst: true,
  enableSorting: true,
}

const totalRevenueColumn = {
  accessorKey: 'totalRevenue',
  header: 'Revenue',
  cell: ({ row }) => formatUSD(row.original.totalRevenue),
  sortingFn: 'basic',
  sortDescFirst: true,
  enableSorting: true,
}

const weeklyCostColumn = {
  accessorKey: 'weeklyCost',
  header: 'Weekly Cost',
  cell: ({ row }) => formatUSD(row.original.weeklyCost),
  sortingFn: 'basic',
  sortDescFirst: true,
  enableSorting: true,
}

const profitColumn = {
  id: 'profit',
  header: 'Profit',
  enableSorting: true,
  cell: ({ row }) => {
    const profit =
      (row.original.totalRevenue || 0) - (row.original.totalCost || 0)
    return formatUSD(profit)
  },
  accessorFn: (row) => {
    return (row.totalRevenue || 0) - (row.totalCost || 0)
  },
  sortingFn: 'basic',
  sortDescFirst: true,
}

const roasColumn = {
  id: 'roas',
  header: 'ROAS',
  enableSorting: true,
  cell: ({ row }) => {
    const cost = row.original.totalCost || 0
    const revenue = row.original.totalRevenue || 0
    const roas = revenue ? revenue / cost : 0
    return formatPercent(roas)
  },
  accessorFn: (row) => {
    const cost = row.totalCost || 0
    const revenue = row.totalRevenue || 0
    return revenue ? revenue / cost : 0
  },
  sortingFn: 'basic',
  sortDescFirst: true,
}

export const columns = [
  idxColumn,
  {
    ...nameColumn,
    enableSorting: false,
  },
  {
    ...mediaSourceColumn,
    enableSorting: false,
  },
  {
    ...countryColumn,
    enableSorting: false,
  },
  {
    ...ownerColumn,
    enableSorting: false,
  },
  {
    ...pidColumn,
    enableSorting: false,
  },
  {
    ...statusColumn,
    enableSorting: false,
  },
  totalRevenueColumn,
  totalCostColumn,
  weeklyCostColumn,
  profitColumn,
  roasColumn,
  createActionsColumn((row) => actionsMenuItems(row)),
]
