import { Outlet } from 'react-router-dom'
import Tabs from '@/components/Tabs'

import { MyDatePicker } from '@/components/MyDatePicker'
import { DropdownPid2, DropdownPid3 } from '@/components/DropdownPid'

const TabItems = [
  { name: 'Revenue', to: '', end: true },
  { name: 'Cost', to: 'cost' },
  { name: 'Profit', to: 'profit' },
]

function Index() {
  return (
    <div className="flex flex-col">
      <div className="flex gap-4 items-center flex-wrap xs:gap-1 sm:gap-2 md:gap-4 md:py-2">
        <DropdownPid2 />
        <DropdownPid3 />
        {/* <DropdownPackageName /> */}
        <MyDatePicker />
      </div>
      <Tabs datas={TabItems} />
      <Outlet />
    </div>
  )
}

export default Index
