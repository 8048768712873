import { formatNumber, formatPercent, formatUSD } from '@/lib/format-utils'
import { cn } from '@/lib/utils'
import { useShowDataStore } from '.'
import CostGoalsCell from '@/components/roi/monthly/cost-goals-cell'

export const Td3 = ({ children, avg, growth }) => {
  const formattedGrowth = formatPercent(growth)
  const { line1, line2, line3 } = useShowDataStore()
  return (
    <div className="flex flex-col text-right">
      {line1 && <div>{children}</div>}
      {line2 && (
        <div className={cn(line1 ? 'text-primary text-xs' : 'text-base')}>
          {avg}
        </div>
      )}
      {line3 && (
        <div
          className={cn(
            'text-xs',
            growth > 0 ? 'text-green-500' : 'text-red-500',
          )}
        >
          {growth > 0 ? <>&uarr;</> : <>&darr;</>}
          {formattedGrowth}
        </div>
      )}
    </div>
  )
}

export const columnDate = {
  id: 'month',
  accessorFn: (row) => row.month,
  header: 'Month',
  cell: (row) => {
    const month = row.getValue()
    return (
      <div className="flex flex-col text-left">
        <div>{month}</div>
      </div>
    )
  },
}

export const columnRevenue = {
  id: 'revenues',
  accessorFn: (row) => {
    const revenue = row.revenues || 0
    const prevRevenue = row.prevRevenues || 0
    const daysInMonth = row.daysInMonth || 30

    const avg = revenue / daysInMonth
    const growth = prevRevenue ? (revenue - prevRevenue) / prevRevenue : 0

    return { revenue, growth, avg }
  },
  header: 'Revenue',
  cell: (row) => {
    const { revenue, growth, avg } = row.getValue()
    const formatted = formatUSD(revenue, 0, 0)
    const formattedAvg = formatUSD(avg, 0, 0)
    return (
      <Td3 avg={formattedAvg} growth={growth}>
        {formatted}
      </Td3>
    )
  },
}

export const columnProfit = {
  id: 'profit',
  accessorFn: (row) => {
    const profit = row.profit || 0
    const prevProfit = row.prevProfit || 0
    const daysInMonth = row.daysInMonth || 30

    const avg = profit / daysInMonth
    const growth = prevProfit ? (profit - prevProfit) / prevProfit : 0

    return { profit, growth, avg }
  },
  header: 'Profit',
  cell: (row) => {
    const { profit, growth, avg } = row.getValue()
    const formatted = formatUSD(profit, 0, 0)
    const formattedAvg = formatUSD(avg, 0, 0)
    return (
      <Td3 avg={formattedAvg} growth={growth}>
        {formatted}
      </Td3>
    )
  },
}

export const columnGrowth = {
  id: 'growth',
  accessorFn: (row) => row.growth,
  header: 'Growth',
  cell: (row) => {
    const growth = row.getValue()
    return (
      <div className={cn(growth > 0 ? 'text-green-500' : 'text-red-500')}>
        {formatPercent(growth)}
      </div>
    )
  },
}

export const columnCPI = {
  id: 'cpi',
  accessorFn: (row) => {
    const cpi = row.cpi || 0
    const prevCpi = row.prevCpi || 0

    const growth = prevCpi ? (cpi - prevCpi) / prevCpi : 0

    return { cpi, growth }
  },
  header: 'CPI',
  cell: (row) => {
    const { cpi, growth } = row.getValue()
    const formatted = formatUSD(cpi, 2, 2)
    return (
      <Td3 avg="-" growth={growth}>
        {formatted}
      </Td3>
    )
  },
}

export const columnCosts = {
  id: 'costs',
  accessorFn: (row) => {
    const costs = row.costs || 0
    const prevCosts = row.prevCosts || 0
    const daysInMonth = row.daysInMonth || 30

    const avg = costs / daysInMonth
    const growth = prevCosts ? (costs - prevCosts) / prevCosts : 0

    return { costs, growth, avg }
  },
  header: 'Costs',
  cell: (row) => {
    const { costs, growth, avg } = row.getValue()
    const formatted = formatUSD(costs, 0, 0)
    const formattedAvg = formatUSD(avg, 0, 0)
    return (
      <Td3 avg={formattedAvg} growth={growth}>
        {formatted}
      </Td3>
    )
  },
}

export const columnEr = (baseline = 0.35) => ({
  id: 'er',
  accessorFn: (row) => {
    const er = row.er / 100
    const prevEr = row.prevEr / 100

    const growth = prevEr ? (er - prevEr) / prevEr : 0

    return { er, growth }
  },
  header: () => (
    <div>
      ER<span className="text-xs">({Math.round(baseline * 100)})%</span>
    </div>
  ),
  cell: (row) => {
    const { er, growth } = row.getValue()
    const formatted = formatPercent(er)
    return (
      <Td3 avg="-" growth={growth}>
        <span className={cn(er > baseline ? 'text-green-500' : 'text-red-500')}>
          {formatted}
        </span>
      </Td3>
    )
  },
})

export const columnInstalls = {
  id: 'installs',
  accessorFn: (row) => {
    const installs = row.installs || 0
    const prevInstalls = row.prevInstalls || 0
    const daysInMonth = row.daysInMonth || 30

    const avg = installs / daysInMonth
    const growth = prevInstalls ? (installs - prevInstalls) / prevInstalls : 0

    return { installs, growth, avg }
  },
  header: 'Installs',
  cell: (row) => {
    const { installs, growth, avg } = row.getValue()
    const formatted = formatNumber(installs)
    const formattedAvg = formatNumber(avg, 0, 0)
    return (
      <Td3 avg={formattedAvg} growth={growth}>
        {formatted}
      </Td3>
    )
  },
}

export const columnGoals = {
  id: 'goals',
  accessorFn: (row) => {
    const goals = row.goals || 0
    return {
      goals,
      monthId: row.monthId,
      refresh: row.refresh,
      pid: row.pid,
      country: row.country,
      costs: row.costs,
    }
  },
  header: 'Cost Goals',
  cell: ({ row }) => <CostGoalsCell data={row.original} />,
}

// Add other columns as needed
