import { useState } from 'react'
import { useParams } from 'react-router-dom'
import useSWR from 'swr'
import { addDays, format } from 'date-fns'
import { Tabs, TabsList, TabsTrigger, TabsContent } from '@/components/ui/tabs'
import { Card } from '@/components/ui/card'
import { DateRangePicker } from '@/components/ui/date-range-picker'
import WeekSelect from '@/components/week-select'
import DailyRoasTable from './daily-roas-table'
import DailyRetTable from './daily-ret-table'
import DailyChart from './daily-chart'
import Day0Chart from './day0-chart'
import { useRoasStore } from '@/stores/roas'
import { formatPercent, formatUSD } from '@/lib/format-utils'
import NotesDrawer from './notes-drawer'
import DailyNotesTable from './daily-notes'
import { useMemo } from 'react'
import Loader from '@/components/zthd/Loader'
import { getWeek } from '@/lib/date-utils'

const CampInfo = ({ id }) => {
  const { data: camp } = useSWR([`/campaigns/${id}`])

  if (!camp) return null

  return (
    <h3 className="text-lg font-medium flex gap-4">
      <span>{camp.name}</span>
      <span>{camp.country}</span>
      <span>{camp.pid}</span>
    </h3>
  )
}

const Detail = () => {
  const { id } = useParams()
  const [date, setDate] = useState({
    from: addDays(new Date(), -30),
    to: new Date(),
  })

  const params = {
    startDate: format(date.from, 'yyyy-MM-dd'),
    endDate: format(date.to, 'yyyy-MM-dd'),
  }
  const {
    data: roasData,
    isLoading,
    isValidating,
  } = useSWR([`/troas/${id}`, params])

  // Fetch ROAS line data
  const { data: roasLineData } = useSWR([`/troas/${id}/roasline`])
  
  const revisedRoas = useMemo(() => {
    if (!roasLineData?.data) return null
    return roasLineData.data
  }, [roasLineData])

  const { data: notesData } = useSWR([`/campaigns/${id}/notes`])

  const weekFilter = useRoasStore((state) => state.weekFilter)

  const summary = useMemo(() => roasData?.summary ?? {}, [roasData])

  const roas = useMemo(() => {
    const redueDailyData = () => {
      const dailyData = roasData?.dailyData ?? []
      if (weekFilter === null) return dailyData

      return dailyData.filter((item) => {
        const [dayIndex] = getWeek(item.install_date)
        return dayIndex === weekFilter
      })
    }
    const dailyData = redueDailyData()
    return { ...roasData, dailyData }
  }, [roasData, weekFilter])

  const notes = useMemo(
    () =>
      notesData?.map((n) => ({
        ...n,
        cid: id,
      })) ?? [],
    [notesData, id],
  )

  if (isLoading || isValidating) return <Loader>Loading...</Loader>

  return (
    <div className="space-y-4 py-4">
      <CampInfo id={id} />
      <Summary summary={summary} />
      <Tabs defaultValue="roas" className="w-full">
        <div className="flex items-center justify-between gap-2 sm:flex-wrap">
          <TabsList className="grid w-[400px] grid-cols-5">
            <TabsTrigger value="roas">Roas</TabsTrigger>
            <TabsTrigger value="chart">Chart</TabsTrigger>
            <TabsTrigger value="day0">Day0</TabsTrigger>
            <TabsTrigger value="ret">Ret</TabsTrigger>
            <TabsTrigger value="notes">Notes</TabsTrigger>
          </TabsList>
          <div className="flex flex-wrap items-center gap-4">
            <DateRangePicker date={date} setDate={setDate} />
            <WeekSelecter />
          </div>
        </div>
        <TabsContent value="roas" className="w-full">
          {roas && <DailyRoasTable roas={roas} revisedRoas={revisedRoas} />}
        </TabsContent>
        <TabsContent value="chart" className="w-full">
          <DailyChart roas={roas} />
        </TabsContent>
        <TabsContent value="day0" className="w-full">
          <Day0Chart roas={roas} />
        </TabsContent>
        <TabsContent value="ret" className="w-full">
          {roas && <DailyRetTable roas={roas} />}
        </TabsContent>
        <TabsContent value="notes" className="w-full">
          <DailyNotesTable notes={notes} />
        </TabsContent>
      </Tabs>
      <NotesDrawer notes={notes} cid={id} />
    </div>
  )
}
const WeekSelecter = () => {
  const weekFilter = useRoasStore((state) => state.weekFilter)
  const setWeekFilter = useRoasStore((state) => state.setWeekFilter)
  return <WeekSelect value={weekFilter} onChange={setWeekFilter} />
}

const Summary = ({ summary }) => {
  if (!summary) return null
  return (
    <Card className="p-4">
      <div className="flex items-center justify-between"></div>
      <div className="grid grid-cols-3 gap-4">
        <div>
          <div className="text-sm text-muted-foreground">Revenue Last 30 Days</div>
          <div className="text-2xl font-bold">
            {formatUSD(summary.totalRevenueLast30Days)}
          </div>
          <div className="text-xs text-muted-foreground">Total Revenue</div>
          <div className="text-xxl">
            {formatUSD(summary.totalRevenue)}
          </div>
        </div>
        <div>
          <div className="text-sm text-muted-foreground">Cost Last 30 Days</div>
          <div className="text-2xl font-bold">
            {formatUSD(summary.totalCostLast30Days)}
          </div>
          <div className="text-xs text-muted-foreground">Total Cost</div>
          <div className="text-xxl">
            {formatUSD(summary.totalCost)}
          </div>
        </div>

        <div>
          <div className="text-sm text-muted-foreground">ROAS Last 30 Days</div>
          <div className="text-2xl font-bold">
            {formatPercent(
              (summary.totalRevenueLast30Days - summary.totalCostLast30Days) / summary.totalRevenueLast30Days,
            )}
          </div>
          <div className="text-xs text-muted-foreground">Total Cost</div>
          <div className="text-xxl">
            {formatPercent(
              (summary.totalRevenue - summary.totalCost) / summary.totalRevenue,
            )}
          </div>
        </div>
      </div>
    </Card>
  )
}

const Toggles = () => {
  const { displayMode, dayMode, setDisplayMode, setDayMode } = useRoasStore()
  return (
    <>
      <Tabs value={displayMode} onValueChange={setDisplayMode}>
        <TabsList>
          <TabsTrigger value="amount">$</TabsTrigger>
          <TabsTrigger value="percentage">%</TabsTrigger>
        </TabsList>
      </Tabs>
      <Tabs value={dayMode} onValueChange={setDayMode}>
        <TabsList>
          <TabsTrigger value="daily">Daily</TabsTrigger>
          <TabsTrigger value="cumulative">Cumulative</TabsTrigger>
        </TabsList>
      </Tabs>
    </>
  )
}

export default Detail
