import { Drawer, DrawerContent } from '@/components/ui/drawer'
import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { useMemo } from 'react'
import { CreateNote } from './notes-create'
import DataTableWithBorder from '@/components/zthd/data-table/data-table-with-border'
import { useRoasNoteStore } from '@/stores/roas'
import { columns } from '@/components/roas/notes/columns'

const NotesDrawer = ({ notes, cid }) => {
  const { open, onOpenChange, date } = useRoasNoteStore()

  const filterNotes = useMemo(
    () =>
      notes?.filter((n) => n.date === Number(date?.replaceAll('-', ''))) ?? [],
    [notes, date],
  )

  const table = useReactTable({
    data: filterNotes,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <Drawer open={open} onOpenChange={onOpenChange}>
      <DrawerContent>
        <div className="mx-auto w-full max-w-auto p-4">
          <div className="flex items-center py-4">
            <CreateNote cid={cid} date={date} />
          </div>
          <div className="overflow-y-auto max-h-80">
            <DataTableWithBorder table={table} />
          </div>
        </div>
      </DrawerContent>
    </Drawer>
  )
}

export default NotesDrawer
