import { Products } from '@/comm'
import { useDatePicker } from '@/components/MyDatePicker'
import dayjs from 'dayjs'
import { useMemo } from 'react'
import { formatNumber, formatPercent, formatUSD } from '@/lib/format-utils'
import { getDateRange } from '@/lib/date-utils'

import { Button } from '@/components/ui/button'
import { createColumnVisibilityStore } from '@/components/zthd/data-table'
import { DataTableViewOptions } from '@/components/zthd/data-table-view-options'
import DataTableWithBorder from '@/components/zthd/data-table/data-table-with-border'
import {
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table'
import {
  useColumnFilters,
  VSWeekSelector,
} from '@/components/data-table-selectors'
import { useCountry } from '@/components/DropdownCountry'

import useSWR from 'swr'
import Td2 from '../../../components/zthd/Td2'
import { columnDate } from './columns'

const useColumnVisibilityStore = createColumnVisibilityStore({}, 'pcc')

const Home = () => {
  const startDate = useDatePicker((state) => state.startDate)
  const endDate = useDatePicker((state) => state.endDate)
  const country = useCountry((state) => state.value)

  const { columnVisibility, setColumnVisibility } = useColumnVisibilityStore()
  const columnFilters = useColumnFilters((state) => state.columnFilters)

  const { data: costs, mutate } = useSWR([
    '/costs/pc',
    { startDate, endDate, country },
  ])

  const data = useMemo(() => {
    const dateRange = getDateRange(dayjs(startDate), dayjs(endDate))
    return dateRange.map((date) => ({
      date,
      datas: costs?.[date],
    }))
  }, [startDate, endDate, costs])

  const columns = useMemo(() => {
    const ps = Products.filter(({ code }) => !['22', '23'].includes(code))
    const rows = []
    rows.push(columnDate)

    ps.forEach((p) => {
      const { code, name } = p
      rows.push({
        id: `${code}`,
        header: `${code}-${name}`,
        columns: [
          {
            id: `${code}-costs`,
            header: `Costs`,
            accessorFn: (row) => {
              const curr = row.datas?.[code]
              const total = row.datas?.total
              return { curr, total }
            },
            cell: (row) => {
              const { curr, total } = row.getValue()
              const formatted = formatUSD(curr?.costs, 0, 0)
              const per = formatPercent(curr?.costs / total?.costs)
              return <Td2 line2={per}>{formatted}</Td2>
            },
          },
          {
            id: `${code}-installs`,
            header: 'Installs',
            accessorFn: (row) => {
              const curr = row.datas?.[code]
              const total = row.datas?.total
              return { curr, total }
            },
            cell: (row) => {
              const { curr, total } = row.getValue()
              const formatted = formatNumber(curr?.installs, 0, 0)
              const per = formatPercent(curr?.installs / total?.installs)
              return <Td2 line2={per}>{formatted}</Td2>
            },
          },
          {
            id: `${code}-cpi`,
            header: 'CPI',
            accessorFn: (row) => {
              const curr = row.datas?.[code]
              return curr?.costs / curr?.installs
            },
            cell: (row) => {
              const cpi = row.getValue()
              const formatted = formatUSD(cpi, 4, 4)
              return <Td2 line2="-">{formatted}</Td2>
            },
          },
        ],
      })
    })
    return rows
  }, [])

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    enableMultiRowSelection: true,
    state: {
      columnFilters,
      columnVisibility,
    },
  })

  return (
    <>
      {/* <pre>{JSON.stringify(columnFilters)}</pre> */}
      <div className="flex items-center gap-1 py-4">
        <VSWeekSelector />
        <Button onClick={mutate}>refresh</Button>
        <DataTableViewOptions table={table} />
      </div>
      <DataTableWithBorder table={table} />
    </>
  )
}

export default Home
