import {
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { Card } from '@/components/ui/card'
import { Button } from '@/components/ui/button'
import dayjs from 'dayjs'
import { useMemo, useState, useCallback } from 'react'
import { cn } from '@/lib/utils'

const colors = {
  organic: '#388E3C',
  stopped: '#E64A19',
  installs: '#1976D2',
}

const campaignMap = {}

const stoppedCampaigns = new Set()

const processData = (data, type) => {
  if (!data) return []

  return data
    .map((item) => {
      const baseData = {
        date: dayjs(item.date).format('MM-DD'),
        revenue_organic: 0,
        revenue_stopped: 0,
        installs_organic: 0,
        installs_stopped: 0,
      }

      item.campaigns.forEach((campaign) => {
        if (!campaign || !campaign.campaignId) {
          console.warn('Invalid campaign data:', campaign)
          return
        }
        if (campaign.revenue < 0.01 && campaign.cost < 0.01 && !campaign.installs) {
          return
        }
        const campaignName = campaign.campaignName || campaign.campaignId
        campaignMap[campaign.campaignId] = campaignName

        if (campaign.state === 2) {
          stoppedCampaigns.add(campaign.campaignId)
        }

        if (campaign.state === 3 || campaign.campaignType === 'restricted') {
          baseData.revenue_organic += campaign.revenue || 0
          baseData.installs_organic += campaign.installs || 0
        } else if (campaign.state === 2 && campaign.cost < 0.01) {
          baseData.revenue_stopped += campaign.revenue || 0
          baseData.installs_stopped += campaign.installs || 0
        } else {
          baseData[`revenue_${campaign.campaignId}`] = campaign.revenue || 0
          baseData[`cost_${campaign.campaignId}`] = campaign.cost || 0
          baseData[`installs_${campaign.campaignId}`] = campaign.installs || 0
        }
      })

      return baseData
    })
    .sort((a, b) => dayjs(a.date).valueOf() - dayjs(b.date).valueOf())
}

const processPercentageData = (chartData, type) => {
  if (!chartData.length) return []

  return chartData.map(dataPoint => {
    const result = { date: dataPoint.date }
    const totalRevenue = getTotal(dataPoint, 'revenue_')
    const totalCost = getTotal(dataPoint, 'cost_')
    const totalInstalls = getTotal(dataPoint, 'installs_')

    Object.keys(dataPoint).forEach(key => {
      if (key === 'date') return

      if (key.startsWith('revenue_')) {
        result[key] = totalRevenue > 0 ? (dataPoint[key] / totalRevenue) * 100 : 0
      } else if (key.startsWith('cost_')) {
        result[key] = totalCost > 0 ? (dataPoint[key] / totalCost) * 100 : 0
      } else if (key.startsWith('installs_')) {
        result[key] = totalInstalls > 0 ? (dataPoint[key] / totalInstalls) * 100 : 0
      }
    })

    return result
  })
}

const getCampaignName = (key) => {
  if (!key) return 'unknown'
  if (key === 'revenue_organic') return 'Organic Revenue'
  if (key === 'revenue_stopped') return 'Stopped Revenue'
  if (key === 'installs_organic') return 'Organic Installs'
  if (key === 'installs_stopped') return 'Stopped Installs'

  const [prefix, campaignId] = key.split('_')
  if (!campaignId) return key.replace(/_/g, ' ')

  const name = campaignMap[campaignId] || campaignId
  if (prefix === 'installs') {
    return `${name}`
  }
  return name
}

const getColor = (key, index, type) => {
  if (!key) return colors.organic
  
  if (key.includes('organic')) return colors.organic
  if (key.includes('stopped')) return colors.stopped
  
  // Extract campaignId for consistent coloring across tabs
  const campaignId = getCampaignId(key)
  const isInstall = key.startsWith('installs_')
  
  // Use same hue for same campaign ID regardless of metric type
  if (campaignId) {
    return `hsl(${parseInt(campaignId) * 30 % 360}, 70%, 50%)`
  }
  
  // Fallback
  if (isInstall) return colors.installs
  return `hsl(${index * 30}, 70%, 50%)`
}

const getTotal = (data, keyPrefix) =>
  Object.keys(data)
    .filter((key) => key.startsWith(keyPrefix))
    .reduce((sum, key) => sum + (data[key] || 0), 0)

const getPercentage = (val, total) =>
  total > 0 ? ((val / total) * 100).toFixed(1) : 0

const sortKeys = (state) => {
  const sortedKeys = Object.keys(state).sort((a, b) => {
    if (a.startsWith('revenue') && b.startsWith('cost')) return -1
    if (a.startsWith('cost') && b.startsWith('revenue')) return 1
    if (a.startsWith('installs') && !b.startsWith('installs')) return 1
    if (!a.startsWith('installs') && b.startsWith('installs')) return -1

    if (a.startsWith('revenue') && b.startsWith('revenue')) {
      if (a === 'revenue_organic') return -1
      if (b === 'revenue_organic') return 1
      if (a === 'revenue_stopped') return -1
      if (b === 'revenue_stopped') return 1
      return state[b].value - state[a].value
    }

    if (a.startsWith('cost') && b.startsWith('cost')) {
      return state[b].value - state[a].value
    }

    if (a.startsWith('installs') && b.startsWith('installs')) {
      if (a === 'installs_organic') return -1
      if (b === 'installs_organic') return 1
      if (a === 'installs_stopped') return -1
      if (b === 'installs_stopped') return 1
      return state[b].value - state[a].value
    }

    return 0
  })

  const sortedInitialState = {}
  sortedKeys.forEach((key) => {
    sortedInitialState[key] = state[key]
  })

  return sortedInitialState
}

const getCampaignId = (str) => {
  return str.split('_')[1]
}

const formatValue = (value, dataKey, displayMode) => {
  if (dataKey.startsWith('installs_')) {
    return displayMode === 'dollar' ? Math.round(value).toLocaleString() : `${value.toFixed(1)}%`
  }
  return displayMode === 'dollar' ? `$${value.toLocaleString()}` : `${value.toFixed(1)}%`
}

const ChartComponent = ({ data, type = 'summary', displayMode = 'dollar' }) => {
  const rawChartData = useMemo(() => processData(data, type), [data, type])

  const chartData = useMemo(() => {
    if (displayMode === 'dollar') {
      return rawChartData
    } else {
      return processPercentageData(rawChartData, type)
    }
  }, [rawChartData, displayMode, type])

  const [visibleSeries, setVisibleSeries] = useState(() => {
    const initialState = {}
    rawChartData.forEach((data) => {
      Object.keys(data).forEach((key) => {
        if (key !== 'date') {
          initialState[key] = {
            visible: true,
            name: getCampaignName(key),
            value: data[key],
          }
        }
      })
    })
    return sortKeys(initialState)
  })

  const toggleSeriesVisibility = (dataKey) => {
    const campId = dataKey.split('_')[1]
    setVisibleSeries((prev) => {
      const newState = { ...prev }
      Object.keys(newState).forEach((key) => {
        if (key.endsWith(`_${campId}`)) {
          newState[key] = {
            ...newState[key],
            visible: !newState[key].visible,
          }
        }
      })
      return newState
    })
  }

  const toggleAllSeriesVisibility = useCallback(() => {
    setVisibleSeries((prev) => {
      const allVisible = Object.values(prev).every((series) => series.visible)
      const newState = { ...prev }
      Object.keys(newState).forEach((key) => {
        newState[key] = {
          ...newState[key],
          visible: !allVisible,
        }
      })
      return newState
    })
  }, [])

  // Determine if installs are present in the data
  const hasInstalls = useMemo(() => {
    return Object.keys(visibleSeries).some(key => key.startsWith('installs_'))
  }, [visibleSeries])

  if (chartData.length === 0) return null

  return (
    <Card className="p-4">
      <ResponsiveContainer width="100%" height={500}>
        <LineChart
          data={chartData}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 60,
          }}
        >
          <XAxis
            dataKey="date"
            stroke="#888888"
            fontSize={12}
            tickLine={false}
            axisLine={false}
            angle={45}
            textAnchor="start"
            height={60}
          />
          <YAxis
            stroke="#888888"
            fontSize={12}
            tickLine={false}
            axisLine={false}
            yAxisId="left"
            tickFormatter={(value) => {
              // Only for revenue and cost, show $ sign
              return displayMode === 'dollar' ? `$${value.toLocaleString()}` : `${value.toFixed(1)}%`
            }}
          />
          {hasInstalls && (
            <YAxis
              stroke={colors.installs}
              fontSize={12}
              tickLine={false}
              axisLine={false}
              orientation="right"
              yAxisId="right"
              tickFormatter={(value) => {
                return displayMode === 'dollar' ? value.toLocaleString() : `${value.toFixed(1)}%`
              }}
            />
          )}
          <Tooltip
            formatter={(value, name, props) => {
              if (!value || value === 0) return ['-', name]

              const currentDateData = rawChartData.find(
                (d) => d.date === props.payload.date,
              )
              const totalCost = getTotal(currentDateData, 'cost_')
              const totalRevenue = getTotal(currentDateData, 'revenue_')
              const totalInstalls = getTotal(currentDateData, 'installs_')

              let formattedValue
              if (displayMode === 'dollar') {
                if (props.dataKey.startsWith('installs_')) {
                  const percentage = getPercentage(value, totalInstalls)
                  formattedValue = `${Math.round(value).toLocaleString()} (${percentage}%)`
                } else {
                  const percentage = props.dataKey.startsWith('cost_')
                    ? getPercentage(value, totalCost)
                    : getPercentage(value, totalRevenue)
                  formattedValue = `$${value.toLocaleString()} (${percentage}%)`
                }
              } else {
                const rawValue = currentDateData[props.dataKey]
                if (props.dataKey.startsWith('installs_')) {
                  formattedValue = `${value.toFixed(1)}% (${Math.round(rawValue).toLocaleString()})`
                } else {
                  formattedValue = `${value.toFixed(1)}% ($${rawValue.toLocaleString()})`
                }
              }

              let formattedName = name
              if (type === 'summary') {
                if (props.dataKey.startsWith('cost_')) {
                  formattedName = `(Cost) ${name}`
                } else if (props.dataKey.startsWith('installs_')) {
                  formattedName = `(Installs) ${name}`
                }
              }

              if (stoppedCampaigns.has(getCampaignId(props.dataKey))) {
                return [formattedValue, `⛔${formattedName}`]
              } else {
                return [formattedValue, formattedName]
              }
            }}
            labelFormatter={(label) => {
              const currentDateData = rawChartData.find((d) => d.date === label)

              const totalCost = getTotal(currentDateData, 'cost_')
              const totalRevenue = getTotal(currentDateData, 'revenue_')
              const totalInstalls = getTotal(currentDateData, 'installs_')

              let labelText = `Date: ${label}`

              if (type === 'summary') {
                labelText += ` | Total Revenue: $${totalRevenue.toLocaleString()} | Total Cost: $${totalCost.toLocaleString()}`
                if (totalInstalls > 0) {
                  labelText += ` | Total Installs: ${totalInstalls.toLocaleString()}`
                }
                return labelText
              }

              if (type === 'installs') {
                return `${labelText} | Total Installs: ${totalInstalls.toLocaleString()}`
              }

              const typeLabel = type === 'cost' ? 'Cost' : 'Revenue'
              const typeValue = type === 'cost' ? totalCost : totalRevenue
              return `${labelText} | Total ${typeLabel}: $${typeValue.toLocaleString()}`
            }}
            labelStyle={{ color: '#000' }}
            contentStyle={{ backgroundColor: '#fff', borderRadius: '6px' }}
          />
          {Object.keys(visibleSeries)
            .filter((key) => {
              if (type === 'revenue') {
                return key.startsWith('revenue_')
              }
              if (type === 'cost') {
                return key.startsWith('cost_')
              }
              if (type === 'installs') {
                return key.startsWith('installs_')
              }
              return true
            })
            .map(
              (key, index) =>
                visibleSeries[key].visible && (
                  <Line
                    key={key}
                    type="monotone"
                    dataKey={key}
                    name={visibleSeries[key].name}
                    stroke={getColor(key, index, type)}
                    strokeDasharray={key.startsWith('installs_') ? '5 5' : undefined}
                    yAxisId={key.startsWith('installs_') ? 'right' : 'left'}
                  />
                ),
            )}
        </LineChart>
      </ResponsiveContainer>
      <div className="flex flex-wrap gap-2">
        <Button variant="secondary" onClick={toggleAllSeriesVisibility}>
          Toggle All
        </Button>
        {Object.keys(visibleSeries)
          .filter((key) => {
            if (type === 'revenue') {
              return key.startsWith('revenue_')
            }
            if (type === 'cost') {
              return key.startsWith('cost_')
            }
            if (type === 'installs') {
              return key.startsWith('installs_')
            }
            return true
          })
          .filter(
            (key, index, self) =>
              self.findIndex((k) => k.split('_')[1] === key.split('_')[1]) ===
              index,
          )
          .map((key, index) => (
            <Button
              key={key}
              onClick={() => toggleSeriesVisibility(key)}
              className="h-8"
              style={{
                backgroundColor: visibleSeries[key].visible
                  ? getColor(key, index, type)
                  : '#d3d3d3',
              }}
            >
              {stoppedCampaigns.has(getCampaignId(key)) ? (
                <s>{getCampaignName(key)}</s>
              ) : (
                getCampaignName(key)
              )}
            </Button>
          ))}
      </div>
    </Card>
  )
}

export const SummaryChart = ({ data, displayMode }) => (
  <ChartComponent data={data} type="summary" displayMode={displayMode} />
)

export const RevenueChart = ({ data, displayMode }) => (
  <ChartComponent data={data} type="revenue" displayMode={displayMode} />
)

export const CostChart = ({ data, displayMode }) => (
  <ChartComponent data={data} type="cost" displayMode={displayMode} />
)

export const InstallsChart = ({ data, displayMode }) => (
  <ChartComponent data={data} type="installs" displayMode={displayMode} />
)

