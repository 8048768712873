import { getWeek } from '@/lib/date-utils'
import Td2 from '../../../components/zthd/Td2'

export const columnDate = {
  id: 'date',
  accessorFn: (row) => row.date,
  header: 'Date',
  cell: (row) => {
    const date = row.getValue()
    const [, week] = getWeek(date)
    return (
      <Td2 line2={<div className="flex gap-1 justify-end">周{week}</div>}>
        {date}
      </Td2>
    )
  },
  enableHiding: false,
  filterFn: (row, columnId, filterValue) => {
    const date = row.getValue(columnId)
    const [weekIndex] = getWeek(date)
    return weekIndex === filterValue
  },
}
