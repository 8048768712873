import { formatUSD, formatPercent } from '@/lib/format-utils'
import Td2 from '@/components/zthd/Td2'

export const countryColumn = (code, name) => ({
  id: code,
  header: name,
  accessorFn: (row) => {
    const curr = row.datas?.[code]
    const total = row.datas?.total
    return { curr, total }
  },
  cell: ({ row }) => {
    const original = row.original
    // const formatted = formatUSD(original.curr, 0, 0)
    // const per = formatPercent(original.curr / original.total)
    // return <Td2 line2={per}>{formatted}</Td2>
    return <>{JSON.stringify(original)}</>
  },
})
