import { create } from 'zustand'

const useRoasStore = create((set) => ({
  fixMode: "raw",
  displayMode: 'percentage',
  dayMode: 'cumulative',
  weekFilter: null, // null means show all days
  setFixMode: (mode) => set({ fixMode: mode }),
  setDisplayMode: (mode) => set({ displayMode: mode }),
  setDayMode: (mode) => set({ dayMode: mode }),
  setWeekFilter: (day) => set({ weekFilter: day }),
}))

export const useRoasNoteStore = create((set) => ({
  date: '',
  cid: '',
  open: false,
  showNotes: (payload) => {
    const { date, cid } = payload
    set({ date, cid, open: true })
  },
  onOpenChange: (value) => {
    if (value) {
      set({ open: true })
    } else {
      set({ open: false, date: '', cid: '' })
    }
  },
}))

export { useRoasStore }
