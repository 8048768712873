import useSWR from 'swr'
import { formatUSD } from '@/lib/format-utils'
import { Badge } from '@/components/ui/badge'

const DetailAtDate = ({ campaignId, date }) => {
  const { data } = useSWR([
    campaignId && date ? `/campaigns/${campaignId}/revenues/${date}` : null,
  ])

  const revenues = data?.data ?? []
  // 对数据按照 install_date 进行倒序排序
  const sortedRevenues = [...revenues].sort((a, b) => 
    new Date(b.install_date) - new Date(a.install_date)
  )
  const total = sortedRevenues.reduce((sum, item) => sum + item.revenue, 0)

  return (
    <div className="p-4">
      <div className="flex items-center justify-between mb-4">
        <h3 className="text-lg font-semibold">Revenue Details</h3>
        <Badge variant="outline" className="text-base">
          Total: {formatUSD(total)}
        </Badge>
      </div>

      <div className="rounded-lg border">
        {/* Header */}
        <div className="grid grid-cols-4 gap-4 p-3 bg-gray-50 text-sm font-medium text-gray-500 border-b">
          <div>Install Date</div>
          <div>Days Since Install</div>
          <div className="text-right">Revenue</div>
          <div className="text-right">Percentage</div>
        </div>

        {/* Data Rows */}
        <div className="divide-y">
          {sortedRevenues.map((item) => (
            <div
              key={item.install_date}
              className="grid grid-cols-4 gap-4 p-3 text-sm hover:bg-gray-50"
            >
              <div>{item.install_date}</div>
              <div>{item.days_since_install}</div>
              <div className="text-right font-medium">
                {formatUSD(item.revenue)}
              </div>
              <div className="text-right font-medium">
                {total ? ((item.revenue / total) * 100).toFixed(1) : 0}%
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default DetailAtDate
