import { NavLink } from 'react-router-dom'
import { cn } from '@/lib/utils'

const TabItem = ({ to, name, end }) => (
  <NavLink
    to={to}
    end={end}
    className={({ isActive }) =>
      cn(`transition-colors border-b-2`, {
        'text-primary font-bold border-b-primary': isActive,
        'text-foreground/60 hover:text-primary  border-b-transparent':
          !isActive,
      })
    }
  >
    {name}
  </NavLink>
)

const Tabs = ({ datas }) => {
  return (
    <div className="flex items-center mt-2 mb-2 gap-4 xs:mt-1 xs:mb-1 lg:gap-6 border-b bg-transparent p-0">
      {datas.map(({ name, to, end }) => (
        <TabItem key={to} to={to} name={name} end={end} />
      ))}
    </div>
  )
}

export default Tabs
