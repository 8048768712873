import React, { useEffect } from 'react'

export function useMediaQuery(query) {
  const [value, setValue] = React.useState(false)

  useEffect(() => {
    function onChange(event) {
      setValue(event.matches)
    }

    const result = matchMedia(query)
    result.addEventListener('change', onChange)
    setValue(result.matches)

    return () => result.removeEventListener('change', onChange)
  }, [query])

  return value
}

export const useWindowSize = () => {
  const [size, setSize] = React.useState([
    window.innerHeight,
    window.innerWidth,
  ])
  React.useLayoutEffect(() => {
    const updateSize = () => {
      setSize([window.innerHeight, window.innerWidth])
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])
  return size
}
