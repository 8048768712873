import { Checkbox } from '@/components/ui/checkbox'
import { createTableStore } from '@/components/zthd/data-table'
import { cn } from '@/lib/utils'
import { LineChart } from 'lucide-react'
import { formatNumber, formatPercent, formatUSD } from '@/lib/format-utils'

const useTableStore = createTableStore({ columnSelection: {} }, 'roi')

const Td2 = ({ children, line2, line1Css, boldLine1 = false, className }) => {
  return (
    <div className={cn('flex flex-col text-right', className)}>
      <div className={cn('text-nowrap', boldLine1 && 'font-bold', line1Css)}>
        {children}
      </div>
      <div className="text-xs text-muted-foreground">{line2}</div>
    </div>
  )
}

export const selectRow = {
  id: 'select',
  header: ({ table }) => (
    <Checkbox
      checked={
        table.getIsAllPageRowsSelected() ||
        (table.getIsSomePageRowsSelected() && 'indeterminate')
      }
      onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
      aria-label="Select all"
    />
  ),
  cell: ({ row }) => (
    <Checkbox
      checked={row.getIsSelected()}
      onCheckedChange={(value) => row.toggleSelected(!!value)}
      aria-label="Select row"
    />
  ),
  enableSorting: false,
  enableHiding: false,
}

export const dnuColumn = {
  accessorKey: 'dnu',
  header: 'Dnu',
  cell: ({ row }) => {
    const number = parseFloat(row.getValue('dnu'))
    const formatted = formatNumber(number)
    const dau = parseFloat(row.getValue('dau'))
    const per = formatPercent(number / dau)
    return <Td2 line2={per}>{formatted}</Td2>
  },
  enableSorting: false,
}

export const dauColumn = {
  accessorKey: 'dau',
  header: 'Dau',
  cell: ({ row }) => {
    const number = parseFloat(row.getValue('dau'))
    const formatted = formatNumber(number)
    return <Td2 line2="-">{formatted}</Td2>
  },
}
export const originalColumn = {
  id: 'original',
  accessorFn: (row) => {
    const dnu = row.dnu
    const installs = row.costs?.total?.installs
    return dnu - installs
  },
  header: 'Original',
  cell: (row) => {
    const number = row.getValue()
    const formatted = formatNumber(number)
    return <Td2 line2="-">{formatted}</Td2>
  },
}

export const installsColumn = {
  accessorKey: 'installs',
  header: () => (
    <div className="text-center">
      Installs
      <div className="text-xs">(Buy)</div>
    </div>
  ),
  cell: ({ row }) => {
    const number = parseFloat(row.getValue('installs'))
    const formatted = formatNumber(number)
    return <Td2 line2="-">{formatted}</Td2>
  },
}

export const revenueColumn = {
  id: 'revenue',
  accessorFn: (row) => row.revenue,
  header: () => <div className="text-right">Revenue</div>,
  cell: ({ row }) => {
    const revenue = parseFloat(row.getValue('revenue'))
    const formatted = formatUSD(revenue, 0, 0)

    return <Td2 line2="-">{formatted}</Td2>
  },
  enableHiding: false,
}

export const costColumn = {
  accessorKey: 'cost',
  header: ({ column }) => <Header column={column}>Cost</Header>,
  cell: ({ row }) => {
    const cost = parseFloat(row.getValue('cost'))
    const formatted = formatUSD(cost, 0, 0)
    return <Td2 line2="-">{formatted}</Td2>
  },
  enableHiding: false,
}
const Header = ({ column, children }) => {
  const columnSelection = useTableStore((state) => state.columnSelection)
  const setColumnSelection = useTableStore((state) => state.setColumnSelection)
  const isVisible = columnSelection[column.id]
  return (
    <div
      className={cn('flex flex-col font-bold items-center text-right')}
      onClick={() => {
        setColumnSelection((prevSelection) => ({
          ...prevSelection,
          [column.id]: !isVisible,
        }))
      }}
    >
      {children}
      {isVisible && (
        <>
          <LineChart className="w-4 h-4" />
        </>
      )}
    </div>
  )
}

export const profitColumn = {
  id: 'profit',
  header: ({ column }) => <Header column={column}>Profit</Header>,
  cell: ({ row }) => {
    const data = row.original
    const costs = data.costs?.total?.costs
    const revenues = data.revenues?.total
    const profit = revenues - costs

    const formatted = formatUSD(profit, 0, 0)
    return <Td2 line2="-">{formatted}</Td2>
  },
  enableHiding: false,
}

export const erColumn = (erBaseLine) => ({
  id: 'er',
  accessorKey: 'er',
  header: () => {
    const formatted = formatPercent(erBaseLine, 0, 0)
    return (
      <div className="flex items-center">
        ER
        <span className="text-xs">({formatted})</span>
      </div>
    )
  },
  cell: ({ row }) => {
    const er = parseFloat(row.getValue('er'))
    const formatted = formatPercent(er / 100)
    return (
      <div className="flex flex-col text-right">
        <div
          className={cn(
            'font-bold',
            er < erBaseLine * 100 ? 'text-destructive' : 'text-primary',
          )}
        >
          {formatted}
        </div>
        <div className="text-xs text-muted">-</div>
      </div>
    )
  },
  enableHiding: false,
})

export const cpnColumn = {
  id: 'cpn',
  accessorFn: (row) => {
    const costs = row?.costs?.total?.costs
    const dnu = row?.dnu
    return costs / dnu
  },
  header: 'C/N',
  cell: (row) => {
    const cpn = row.getValue()
    const formatted = formatUSD(cpn, 4, 4)
    return <Td2 line2="-">{formatted}</Td2>
  },
}

export const cpiColumn = {
  id: 'cpi',
  accessorFn: (row) => {
    const costs = row.costs?.total.costs
    const installs = row.costs?.total.installs
    const cpi = costs / installs
    return cpi
  },
  header: 'CPI',
  cell: (row) => {
    const cpi = row.getValue()
    const formatted = formatUSD(cpi, 4, 4)
    return <Td2 line2="-">{formatted}</Td2>
  },
}

export const rpiColumn = {
  id: 'rpi',
  accessorFn: (row) => {
    const revenues = row.revenues?.total
    const installs = row.costs?.total.installs
    const cpi = revenues / installs
    return cpi
  },
  header: 'RPI',
  cell: (row) => {
    const cpi = row.getValue()
    const formatted = formatUSD(cpi, 4, 4)
    return <Td2 line2="-">{formatted}</Td2>
  },
}

export const rpnColumn = {
  id: 'rpn',
  accessorFn: (row) => {
    const revenue = row.revenue
    const dnu = row.dnu
    return revenue / dnu
  },
  accessorKey: 'rpn',
  header: 'R/N',
  cell: (row) => {
    const rpn = row.getValue()
    const formatted = formatUSD(rpn, 4, 4)
    return <Td2 line2="-">{formatted}</Td2>
  },
}

export const rpaColumn = {
  id: 'rpa',
  accessorFn: (row) => {
    const revenue = row.revenue
    const dau = row.dau
    return revenue / dau
  },
  header: 'R/A',
  cell: (row) => {
    const rpa = row.getValue()
    const formatted = formatUSD(rpa, 4, 4)
    return <Td2 line2="-">{formatted}</Td2>
  },
}

export const ltvColumn = {
  id: 'ltv',
  accessorFn: (row) => {
    const profit = row.profit
    const installs = row.installs
    const ltv = profit / installs
    return ltv
  },
  header: 'LTV',
  cell: (row) => {
    const ltv = row.getValue()
    const formatted = formatUSD(ltv, 4, 4)
    return <Td2 line2="-">{formatted}</Td2>
  },
}

export const costsDetailColumn = {
  id: 'costs',
  header: 'Costs',
  columns: [
    ...['facebook', 'ads', 'ads_30', 'apple_ads', 'tiktok'].map((source) => ({
      id: `costs-${source}`,
      header: source,
      columns: [
        {
          id: `costs-${source}-cost`,
          header: 'Cost',
          accessorFn: (row) => {
            const total = row?.costs?.total?.costs
            const curr = row?.costs?.[source]?.costs
            return { total, curr }
          },
          cell: (row) => {
            const meta = row.getValue()
            const { total, curr } = meta
            const formatted = formatUSD(curr, 0, 0)
            const per = formatPercent(curr / total)
            return <Td2 line2={per}>{formatted}</Td2>
          },
        },
        {
          id: `costs-${source}-install`,
          header: 'Install',
          accessorFn: (row) => {
            const total = row?.costs?.total?.installs
            const curr = row?.costs?.[source]?.installs
            return { total, curr }
          },
          cell: (row) => {
            const meta = row.getValue()
            const { total, curr } = meta
            const formatted = formatNumber(curr)
            const per = formatPercent(curr / total)
            return <Td2 line2={per}>{formatted}</Td2>
          },
        },
        {
          id: `costs-${source}-cpi`,
          header: 'CPI',
          accessorFn: (row) => {
            const costs = row?.costs?.[source]?.costs
            const installs = row?.costs?.[source]?.installs
            return costs / installs
          },
          cell: (row) => {
            const meta = row.getValue()
            const formatted = formatUSD(meta, 4, 4)
            return <Td2 line2="-">{formatted}</Td2>
          },
        },
      ],
    })),
  ],
}

export const revenueDetailColumn = {
  id: 'revenue-group',
  header: 'Revenue',
  columns: [
    ...[
      'admob',
      'facebook',
      'pangle',
      'applovin',
      'unity',
      'applovin_admob',
      'vungle',
      'mintegral',
      'apple',
      'ironsource',
      'fyber',
      'moloco',
      'google_play_subscription',
    ].map((source) => ({
      id: `revenues-${source}`,
      accessorKey: 'revenues',
      header: () => {
        if (source === 'applovin_admob') {
          return (
            <div className="text-right">
              <div className="capitalize">Applovin</div>
              <div>
                <small>(admob)</small>
              </div>
            </div>
          )
        } else if (source === 'google_play_subscription') {
          return (
            <div className="text-right">
              <div className="capitalize">Google Play</div>
              <div>
                <small>(subscription)</small>
              </div>
            </div>
          )
        }
        return <div className="text-right">{source}</div>
      },
      cell: (row) => {
        const revenues = row.getValue('revenues')
        const revenue = parseFloat(revenues?.[source])
        const total = revenues?.total
        const formatted = formatUSD(revenue, 0, 0)
        const per = formatPercent(revenue / total)
        return <Td2 line2={per}>{formatted}</Td2>
      },
    })),
  ],
}
