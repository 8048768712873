import dayjs from 'dayjs'
import { TrashIcon } from '@radix-ui/react-icons'
import useSWRMutation from 'swr/mutation'
import { delFetcher, mutateRequest } from '@/hooks'
import { Loader2 } from 'lucide-react'
import MyLink from '@/components/zthd/my-link'
import { CustomBadge } from '@/components/zthd/badge'
import { getStatusConfig } from '@/comm/campaign-status'
export const IndexColumn = {
  id: 'index',
  header: '#',
  cell: ({ row }) => row.index + 1,
}

export const DateColumn = {
  accessorKey: 'date',
  header: 'Date',
  cell: ({ row }) => row.getValue('date'),
}

export const ReasonColumn = {
  accessorKey: 'reason',
  header: 'Note',
  cell: ({ row }) => row.getValue('reason'),
}

export const CreatedAtColumn = {
  accessorKey: 'createdAt',
  header: 'Logtime',
  cell: ({ row }) => {
    const updateTime = row.getValue('createdAt')
    const formatted = dayjs(updateTime).format('YYYY-MM-DD LT')
    return formatted
  },
}
export const OptColumn = {
  id: 'opt',
  accessorFn: (row) => row.id,
  header: 'Opt',
  cell: ({ row }) => <ActionCell row={row} />,
}

export const CampaignNameColumn = {
  id: 'campaignName',
  header: 'Campaign Name',
  cell: ({ row }) => {
    const note = row.original
    return (
      <div className="flex items-center gap-2">
        <MyLink to={`/troas/campaigns/${note.campaignId}`} target="_blank">
          <span>{note.campaignName}</span>
        </MyLink>
      </div>
    )
  },
}

export const CampaignStateColumn = {
  id: 'campaignState',
  header: 'Campaign State',
  cell: ({ row }) => {
    const note = row.original

    const status = getStatusConfig(note.campaignState)

    return (
      <CustomBadge variant={status.variant} size="sm">
        {status.label}
      </CustomBadge>
    )
  },
}

export const MediaSourceColumn = {
  id: 'mediaSource',
  header: 'Media Source',
  cell: ({ row }) => {
    const note = row.original
    return <span>{note.mediaSource}</span>
  },
}

export const CountryColumn = {
  id: 'country',
  header: 'Country',
  cell: ({ row }) => {
    const note = row.original
    return <span>{note.country}</span>
  },
}
export const columns = [
  IndexColumn,
  DateColumn,
  ReasonColumn,
  CreatedAtColumn,
  OptColumn,
]

const ActionCell = ({ row }) => {
  const note = row.original
  const id = note.id
  const cid = note.cid
  const { trigger, isMutating } = useSWRMutation(
    `/campaigns/${cid}/notes/`,
    delFetcher,
  )

  const handleDelete = async () => {
    if (isMutating) {
      return
    }
    await trigger(id)
    mutateRequest(`/campaigns/${cid}/notes`)
  }

  if (isMutating) {
    return <Loader2 className="h-4 w-4 animate-spin" />
  }

  return (
    <TrashIcon
      className={`h-4 w-4 text-destructive hover:outline`}
      onClick={handleDelete}
    />
  )
}
