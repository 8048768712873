import { getWeek } from '@/lib/date-utils'
import { Button } from '@/components/ui/button'
import { useRoasNoteStore } from '@/stores/roas'
import useSWR from 'swr'
import { useMemo } from 'react'
import { cn } from '@/lib/utils'
import { BookmarkFilledIcon } from '@radix-ui/react-icons'
const InstallDateCell = ({ row, campaignId }) => {
  const roas = row.original
  const [, weekDay] = getWeek(roas.install_date)
  const { showNotes } = useRoasNoteStore()
  const { data: notes } = useSWR([
    campaignId ? `/campaigns/${campaignId}/notes` : null,
  ])
  const hasNote = useMemo(
    () =>
      notes?.some(
        (n) => n.date === Number(roas.install_date.replaceAll('-', '')),
      ),
    [notes, roas.install_date],
  )

  const handleNote = () => {
    showNotes({ date: roas.install_date, cid: campaignId })
  }

  return (
    <div className="flex flex-col">
      <div>{roas.install_date}</div>
      <div className="flex items-center gap-2 text-xs text-muted-foreground">
        星期{weekDay}
        <Button
          variant="ghost"
          size="sm"
          className={cn('p-0 h-4 w-4', hasNote && 'text-green-500')}
          onClick={handleNote}
        >
          <BookmarkFilledIcon />
        </Button>
      </div>
    </div>
  )
}

export default InstallDateCell
